import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/qr-tel.png'


const _hoisted_1 = { class: "w-full h-full" }
const _hoisted_2 = { class: "flex flex-col h-full" }
const _hoisted_3 = { class: "bg-neutral-100 h-[56vw] relative" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "absolute w-full h-full p-[6vw] text-white top-0 right-0 flex flex-col bg-neutral-800/35" }
const _hoisted_6 = { class: "border border-white rounded w-[14vw] h-[14vw] flex flex-col justify-center items-center ms-auto" }
const _hoisted_7 = { class: "text-[2.2vh] font-bold leading-tight mb-0" }
const _hoisted_8 = { class: "text-[1.4vh] font-semibold" }
const _hoisted_9 = { class: "text-[2.2vh] font-bold leading-tight mb-0" }
const _hoisted_10 = { class: "text-[1.4vh] font-semibold" }
const _hoisted_11 = { class: "text-[3.8vh] font-bold leading-tight mt-auto" }
const _hoisted_12 = { class: "h-full relative" }
const _hoisted_13 = { class: "h-full overflow-y-auto scrollbar-none" }
const _hoisted_14 = { class: "gradient-gray p-[6vw] pb-[12vw] text-gray-theme text-[1.8vh] font-semibold" }
const _hoisted_15 = { class: "rounded-t-3xl mt-[-4vh] bg-white pt-[6vw] pb-[35vh] shadow-top" }
const _hoisted_16 = { class: "ps-[6vw] pe-[8vw] pt-[2vw] pb-[2vw]" }
const _hoisted_17 = { class: "relative gradient-gray rounded-3xl p-[4vw] pe-[21vw] text-gray-theme flex gap-[4vw] items-center" }
const _hoisted_18 = { class: "absolute top-[-2vw] right-[-2vw] rounded-xl shadow-center bg-white p-[2vw]" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "serif p-[6vw]" }
const _hoisted_21 = { class: "text-[1.6vh] leading-relaxed space-y-6" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = {
  key: 0,
  class: "px-[6vw] pb-[5vh]"
}

import { onMounted, onBeforeUnmount, ref, computed, watch } from "vue";
import { Icon } from "@iconify/vue";
import { getMonth } from "@/helpers/helpers";
import { getDay } from "@/helpers/helpers";
import MapComponent from "@/components/MapComponent.vue";
import { useLayoutStore } from "@/stores/layoutStore";
import PdfViewerComponent from "./PdfViewerComponent.vue";
import GalleryComponent from "./GalleryComponent.vue";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'NewsEventDetail',
  props: {
    data: {}
  },
  setup(__props: any) {

const layoutStore = useLayoutStore();

const props = __props;

//aggiungere tutte le condizioni in or

const isMapFullScreen = ref(false);
const isGalleryFullScreen = ref(false);
const isPdfFullScreen = ref(false);

const isLayoutBusy = computed(() => isMapFullScreen.value || isGalleryFullScreen.value || isPdfFullScreen.value);
watch(isLayoutBusy, (newValue) => {
  layoutStore.isLayoutBusy = newValue;
});

const images = computed(() => {
  return props.data?.gallery?.map((image: any) => image.url) || [];
})

const pdfList = computed(() => {
  if (props.data?.files?.length>0)
    return props.data?.files|| [];
  else return [];
})
console.log(props.data, "data detail");

const mapEnterFullScreen = () => {
  isMapFullScreen.value = true;
};

const mapExitFullScreen = () => {
  isMapFullScreen.value = false;
};

const galleryEnterFullScreen = () => {
  isGalleryFullScreen.value = true;
};
const galleryExitFullScreen = () => {
  isGalleryFullScreen.value = false;
};

const pdfEnterFullScreen = () => {
  isPdfFullScreen.value = true;
};
const pdfExitFullScreen = () => {
  isPdfFullScreen.value = false;
};

const handleGoBack = () => {
  if (!!isMapFullScreen.value) mapExitFullScreen();
  else if (!!isGalleryFullScreen.value) galleryExitFullScreen();
  else if (!!isPdfFullScreen.value) pdfExitFullScreen();
};

onMounted(() => {
  window.addEventListener("navigation:goBack", handleGoBack);
});

onBeforeUnmount(() => {
  window.removeEventListener("navigation:goBack", handleGoBack);
});


const goToActivityPage = () => {
  if (props.data.id) {
    // Naviga alla rotta dinamica con l'ID dell'attività
    router.push({ name: 'Activity', params: { id: props.data.id } });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.image, (img, index) => {
          return (_openBlock(), _createElementBlock("img", {
            key: index,
            src: img?.url ? img.url : '',
            class: "w-full h-full object-cover"
          }, null, 8, _hoisted_4))
        }), 128)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_ctx.data?.date)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(getDay)(_ctx.data?.date)), 1),
                  _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(getMonth)(_ctx.data?.date)), 1)
                ], 64))
              : (_ctx.data?.start_date && _ctx.data?.end_date)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.data?.start_date ? _unref(getDay)(_ctx.data?.start_date) : "") + " - " + _toDisplayString(_ctx.data?.end_date ? _unref(getDay)(_ctx.data?.end_date) : ""), 1),
                    _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.data?.start_date ? _unref(getMonth)(_ctx.data?.start_date) : ""), 1)
                  ], 64))
                : _createCommentVNode("", true)
          ]),
          _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.data?.title), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "w-full h-[1.2vh] absolute z-10 top-0 left-0 bg-gradient-to-b from-black/20 to-transparent" }, null, -1)),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.data?.abstract), 1),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _cache[0] || (_cache[0] = _createElementVNode("img", {
                  src: _imports_0,
                  class: "w-[10vw]"
                }, null, -1)),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "leading-tight" }, [
                  _createElementVNode("h4", { class: "text-[2.8vh] font-bold" }, "Portami con te!"),
                  _createElementVNode("p", { class: "text-[1.8vh] font-semibold mt-1" }, " Scansiona il codice QR e leggi questa pagina sul tuo smartphone ")
                ], -1)),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("img", {
                    src: 'data:image/png;base64,' + _ctx.data?.qr_code,
                    alt: "QR Code",
                    class: "w-[16vw]"
                  }, null, 8, _hoisted_19)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("p", {
                  innerHTML: _ctx.data?.body
                }, null, 8, _hoisted_22)
              ])
            ]),
            (_ctx.data?.files?.length>0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createVNode(PdfViewerComponent, {
                    "pdf-list": pdfList.value,
                    fullScreen: isPdfFullScreen.value,
                    onPdfEnterFullScreen: pdfEnterFullScreen,
                    onPdfExitFullScreen: pdfExitFullScreen
                  }, null, 8, ["pdf-list", "fullScreen"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.data?.position)
              ? (_openBlock(), _createBlock(MapComponent, {
                  key: 1,
                  latitude: _ctx.data.position?.latitude,
                  address: _ctx.data.position?.address,
                  longitude: _ctx.data.position?.longitude,
                  fullScreen: isMapFullScreen.value,
                  onMapEnterFullScreen: mapEnterFullScreen,
                  onMapExitFullScreen: mapExitFullScreen
                }, null, 8, ["latitude", "address", "longitude", "fullScreen"]))
              : _createCommentVNode("", true),
            (images.value?.length > 0)
              ? (_openBlock(), _createBlock(GalleryComponent, {
                  key: 2,
                  images: images.value,
                  fullScreen: isGalleryFullScreen.value,
                  onGalleryEnterFullScreen: galleryEnterFullScreen,
                  onGalleryExitFullScreen: galleryExitFullScreen
                }, null, 8, ["images", "fullScreen"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}
}

})