<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Livello 1"
    viewBox="0 0 50 50"
    fill="currentColor"
  >
    <path
      d="m12.38 11.45 9.96 5.75a6.22 6.22 0 0 0 4.7.62 6.161 6.161 0 0 0 3.76-2.88 6.22 6.22 0 0 0 .62-4.7 6.161 6.161 0 0 0-2.88-3.76 6.739 6.739 0 0 0-5.05-.62 2.066 2.066 0 0 0-1.36 2.5 2.059 2.059 0 0 0 2.43 1.47c.64-.17 1.32-.1 1.91.22a2.057 2.057 0 0 1 .75 2.82 2.057 2.057 0 0 1-2.82.75l-9.96-5.75c-.47-.27-1.04-.35-1.57-.21-.53.14-.98.49-1.25.96s-.35 1.04-.21 1.57c.14.53.49.98.96 1.25Z"
    />
    <path
      d="m6.15 14.99 26.2 15.12a6.22 6.22 0 0 0 4.7.62 6.161 6.161 0 0 0 3.76-2.88 6.22 6.22 0 0 0 .62-4.7 6.161 6.161 0 0 0-2.88-3.76 6.762 6.762 0 0 0-5.06-.63 2.066 2.066 0 0 0-1.36 2.5 2.059 2.059 0 0 0 2.43 1.47c.64-.18 1.33-.1 1.92.22a2.057 2.057 0 0 1 .75 2.82 2.057 2.057 0 0 1-2.82.75l-26.2-15.1c-.47-.27-1.04-.35-1.57-.21-.53.14-.98.49-1.25.96s-.35 1.04-.21 1.57c.14.53.49.98.96 1.25ZM3.5 19.38v25h43l-43-25z"
    />
  </svg>
</template>
