import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import {
  ref,
  reactive,
  watch,
  onMounted,
  computed,
} from "vue";
import { Icon } from "@iconify/vue";

// Define props with default values

export default /*@__PURE__*/_defineComponent({
  __name: 'MapComponent',
  props: {
    latitude: { default: 44.494887 },
    longitude: { default: 11.3426163 },
    address: { default: "" },
    fullScreen: { type: Boolean, default: false }
  },
  emits: ["mapEnterFullScreen", "mapExitFullScreen"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

// Define emits
const emit = __emit;

// Map settings
const zoom = ref(16);
const center :any = computed(() => ({
  lat: props.latitude,
  lng: props.longitude,
}));

// Full-screen control
const isFullScreen = computed(() => props.fullScreen);
const toggleFullScreen = () => {
  emit(isFullScreen.value ? "mapExitFullScreen" : "mapEnterFullScreen");
};

// Map styles based on full-screen status
const mapStyles = computed(() => ({
  width: "100%",
  height: isFullScreen.value ? "100vh" : "46vw",
}));

// Hide controls
const mapOptions = {
  zoomControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
};

// Hide Google Maps default full-screen button
const hideDefaultFullScreenButton = () => {
  const attemptHide = () => {
    const mapRef = document.querySelector(".gm-fullscreen-control") as HTMLElement;
    if (mapRef) {
      mapRef.style.display = "none";
    } else {
      // Retry if the button is not yet available
      setTimeout(attemptHide, 100);
    }
  };
  attemptHide();
};

// Watch for prop changes in latitude and longitude
watch(
  () => [props.latitude, props.longitude],
  ([newLatitude, newLongitude]) => {
    center.value = { lat: newLatitude, lng: newLongitude };
  }
);

return (_ctx: any,_cache: any) => {
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['custom-autocomplete mb-[5vh]', { 'full-screen': isFullScreen.value }])
  }, [
    _createVNode(_component_GMapMap, {
      ref: "mapRef",
      center: center.value,
      zoom: zoom.value,
      options: mapOptions,
      "map-type-id": "terrain",
      style: _normalizeStyle(mapStyles.value),
      onTilesloaded: hideDefaultFullScreenButton
    }, {
      default: _withCtx(() => [
        _createVNode(_component_GMapMarker, {
          position: center.value,
          title: _ctx.address
        }, null, 8, ["position", "title"])
      ]),
      _: 1
    }, 8, ["center", "zoom", "style"]),
    _createElementVNode("button", {
      onClick: toggleFullScreen,
      class: "custom-fullscreen-btn absolute top-[2vw] right-[2vw] shadow bg-white border border-neutral-300 p-[1vw]"
    }, [
      (isFullScreen.value)
        ? (_openBlock(), _createBlock(_unref(Icon), {
            key: 0,
            icon: "ic:baseline-zoom-in-map",
            class: "w-[4vw] h-[4vw]"
          }))
        : (_openBlock(), _createBlock(_unref(Icon), {
            key: 1,
            icon: "ic:baseline-zoom-out-map",
            class: "w-[4vw] h-[4vw]"
          }))
    ])
  ], 2))
}
}

})