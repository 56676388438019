import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "carousel" }

import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  watch
} from 'vue';
import ImageContent from '@/components/ImageContent.vue';

// Define props

export default /*@__PURE__*/_defineComponent({
  __name: 'CarouselComponent',
  props: {
    media: {},
    transitionTime: {},
    transitionType: {},
    transitionDuration: { default: 1000 },
    transitionEasing: { default: 'ease' }
  },
  setup(__props: any) {

const props = __props;

// State management
const currentIndex = ref(0);
const nextIndex = ref((currentIndex.value + 1) % props.media.length);
const interval = ref<number | null>(null);

// Computed properties for contents
const contents = computed(() =>
  props.media.map((media) => ({
    component: ImageContent,
    props: { src: media }
  }))
);

const currentContent = computed(() => contents.value[currentIndex.value]);
const nextContent = computed(() => contents.value[nextIndex.value]);

// Check if there are multiple contents
const hasMultipleContents = computed(() => props.media.length > 1);

// Styles for controlling fade and scroll transitions
const currentStyle = ref({});
const nextStyle = ref({});

// Helper to get transition style string based on props
const transitionStyle = computed(
  () => `${props.transitionDuration}ms ${props.transitionEasing}`
);

// Apply transition effects only if there are multiple contents
const applyTransition = () => {
  if (!hasMultipleContents.value) return;

  if (props.transitionType === 'fade') {
    // Set initial styles without transition
    currentStyle.value = { opacity: 1 };
    nextStyle.value = { opacity: 0 };

    // Use a short delay to apply transition
    setTimeout(() => {
      currentStyle.value = {
        opacity: 0,
        transition: `opacity ${transitionStyle.value}`
      };
      nextStyle.value = {
        opacity: 1,
        transition: `opacity ${transitionStyle.value}`
      };
    }, 50);
  } else if (props.transitionType === 'scroll') {
    // Set initial styles without transition
    currentStyle.value = { transform: 'translateX(0)' };
    nextStyle.value = { transform: 'translateX(100%)' };

    // Use a short delay to apply transition
    setTimeout(() => {
      currentStyle.value = {
        transform: 'translateX(-100%)',
        transition: `transform ${transitionStyle.value}`
      };
      nextStyle.value = {
        transform: 'translateX(0)',
        transition: `transform ${transitionStyle.value}`
      };
    }, 50);
  }
};

// Carousel control functions
const startCarousel = () => {
  if (!hasMultipleContents.value) return;

  interval.value = setInterval(() => {
    applyTransition();

    setTimeout(() => {
      // After the transition, update indices
      currentIndex.value = nextIndex.value;
      nextIndex.value = (currentIndex.value + 1) % contents.value.length;

      // Reset styles for the next transition
      if (props.transitionType === 'fade') {
        currentStyle.value = { opacity: 1 };
        nextStyle.value = { opacity: 0 };
      } else if (props.transitionType === 'scroll') {
        currentStyle.value = { transform: 'translateX(0)' };
        nextStyle.value = { transform: 'translateX(100%)' };
      }
    }, props.transitionDuration); // Use transitionDuration for timing
  }, props.transitionTime);
};

const stopCarousel = () => {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
};

// Lifecycle hooks
onMounted(startCarousel);
onUnmounted(stopCarousel);

watch(
  () => props.transitionTime,
  () => {
    stopCarousel();
    startCarousel();
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(currentContent.value.component), _mergeProps(currentContent.value.props, {
      class: "carousel-content current",
      style: currentStyle.value
    }), null, 16, ["style"])),
    (hasMultipleContents.value)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(nextContent.value.component), _mergeProps({ key: 0 }, nextContent.value.props, {
          class: "carousel-content next",
          style: nextStyle.value
        }), null, 16, ["style"]))
      : _createCommentVNode("", true)
  ]))
}
}

})