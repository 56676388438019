<template>
  <StandBy v-if="mainStore.isStandbyVisible" class="standby-view" />
  <template v-else>
    <div v-if="mainStore.hasError">Error: Unable to load content</div>
    <div v-else-if="mainStore.isLoading">Loading...</div>
    <template v-else>
      <HeaderBar id="header-bar" />
      <LayoutComponent
        id="layout-component"
        :layout-type="settingsStore.layoutType"
      >
      </LayoutComponent>
      <NavigationBar id="navigation-bar" />
    </template>
  </template>
</template>

<script setup lang="ts">
import { useMainStore } from "@/stores/mainStore";
import { useSettingsStore } from "@/stores/settingsStore";
import { onMounted, watch } from "vue";
import StandBy from "@/components/StandBy.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import HeaderBar from "@/components/HeaderBar.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";

const mainStore = useMainStore();
const settingsStore = useSettingsStore();

onMounted(() => {
  console.log("App mounted");
  mainStore.initializeApp();
  document.documentElement.requestFullscreen();
});

// Listen for user interactions and restart standby timer

const addStandByEventListeners = () => {
  document.addEventListener("click", () =>
    mainStore.toggleStandbyVisibility(false)
  );

  document.addEventListener("mousemove", () => mainStore.startStandbyTimer());
  document.addEventListener("keydown", () => mainStore.startStandbyTimer());

  // Touch Events
  document.addEventListener("touchstart", () => mainStore.startStandbyTimer());
  document.addEventListener("touchmove", () => mainStore.startStandbyTimer());
  document.addEventListener("touchend", () => mainStore.startStandbyTimer());
  document.addEventListener("touchcancel", () => mainStore.startStandbyTimer());

  // Swipe Gesture Handling
  let touchStartX = 0;
  let touchStartY = 0;

  document.addEventListener("touchstart", (event) => {
    touchStartX = event.touches[0].clientX;
    touchStartY = event.touches[0].clientY;
  });

  document.addEventListener("touchend", (event) => {
    const touchEndX = event.changedTouches[0].clientX;
    const touchEndY = event.changedTouches[0].clientY;
    const deltaX = touchEndX - touchStartX;
    const deltaY = touchEndY - touchStartY;

    // Check if the movement qualifies as a swipe
    if (Math.abs(deltaX) > 30 || Math.abs(deltaY) > 30) {
      mainStore.startStandbyTimer();
    }
  });
};

watch(
  () => [mainStore.isStandByActive],
  ([isActive]) => {
    if (isActive) {
      addStandByEventListeners();
    }
  }
);
</script>

<style lang="scss">
#app {
  font-family: "TitilliumWeb", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /* aspect-ratio: 9 / 16;  Maintains a 16:9 aspect ratio */
  width: 100vw; /* Ensure it doesn't overflow the viewport width */
  background-color: white;
  position: relative;
}

.standby-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
</style>
