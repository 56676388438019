import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

import { useMainStore } from "@/stores/mainStore";
import { useSettingsStore } from "@/stores/settingsStore";
import { onMounted, watch } from "vue";
import StandBy from "@/components/StandBy.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import HeaderBar from "@/components/HeaderBar.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const mainStore = useMainStore();
const settingsStore = useSettingsStore();

onMounted(() => {
  console.log("App mounted");
  mainStore.initializeApp();
  document.documentElement.requestFullscreen();
});

// Listen for user interactions and restart standby timer

const addStandByEventListeners = () => {
  document.addEventListener("click", () =>
    mainStore.toggleStandbyVisibility(false)
  );

  document.addEventListener("mousemove", () => mainStore.startStandbyTimer());
  document.addEventListener("keydown", () => mainStore.startStandbyTimer());

  // Touch Events
  document.addEventListener("touchstart", () => mainStore.startStandbyTimer());
  document.addEventListener("touchmove", () => mainStore.startStandbyTimer());
  document.addEventListener("touchend", () => mainStore.startStandbyTimer());
  document.addEventListener("touchcancel", () => mainStore.startStandbyTimer());

  // Swipe Gesture Handling
  let touchStartX = 0;
  let touchStartY = 0;

  document.addEventListener("touchstart", (event) => {
    touchStartX = event.touches[0].clientX;
    touchStartY = event.touches[0].clientY;
  });

  document.addEventListener("touchend", (event) => {
    const touchEndX = event.changedTouches[0].clientX;
    const touchEndY = event.changedTouches[0].clientY;
    const deltaX = touchEndX - touchStartX;
    const deltaY = touchEndY - touchStartY;

    // Check if the movement qualifies as a swipe
    if (Math.abs(deltaX) > 30 || Math.abs(deltaY) > 30) {
      mainStore.startStandbyTimer();
    }
  });
};

watch(
  () => [mainStore.isStandByActive],
  ([isActive]) => {
    if (isActive) {
      addStandByEventListeners();
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_unref(mainStore).isStandbyVisible)
    ? (_openBlock(), _createBlock(StandBy, {
        key: 0,
        class: "standby-view"
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_unref(mainStore).hasError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Error: Unable to load content"))
          : (_unref(mainStore).isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Loading..."))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createVNode(HeaderBar, { id: "header-bar" }),
                _createVNode(LayoutComponent, {
                  id: "layout-component",
                  "layout-type": _unref(settingsStore).layoutType
                }, null, 8, ["layout-type"]),
                _createVNode(NavigationBar, { id: "navigation-bar" })
              ], 64))
      ], 64))
}
}

})