<template>
  <div class="carousel">
    <!-- Current content -->
    <component
      :is="currentContent.component"
      v-bind="currentContent.props"
      class="carousel-content current"
      :style="currentStyle"
    />

    <!-- Next content (only shown if more than one item) -->
    <component
      v-if="hasMultipleContents"
      :is="nextContent.component"
      v-bind="nextContent.props"
      class="carousel-content next"
      :style="nextStyle"
    />
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  watch,
  withDefaults,
  defineProps
} from 'vue';
import ImageContent from '@/components/ImageContent.vue';

// Define props
const props = withDefaults(
  defineProps<{
    media: Array<string>;
    transitionTime: number;
    transitionType: string;
    transitionDuration?: number; // Optional with default
    transitionEasing?: string; // Optional with default
  }>(),
  {
    transitionDuration: 1000, // Default 1 second
    transitionEasing: 'ease' // Default easing
  }
);

// State management
const currentIndex = ref(0);
const nextIndex = ref((currentIndex.value + 1) % props.media.length);
const interval = ref<number | null>(null);

// Computed properties for contents
const contents = computed(() =>
  props.media.map((media) => ({
    component: ImageContent,
    props: { src: media }
  }))
);

const currentContent = computed(() => contents.value[currentIndex.value]);
const nextContent = computed(() => contents.value[nextIndex.value]);

// Check if there are multiple contents
const hasMultipleContents = computed(() => props.media.length > 1);

// Styles for controlling fade and scroll transitions
const currentStyle = ref({});
const nextStyle = ref({});

// Helper to get transition style string based on props
const transitionStyle = computed(
  () => `${props.transitionDuration}ms ${props.transitionEasing}`
);

// Apply transition effects only if there are multiple contents
const applyTransition = () => {
  if (!hasMultipleContents.value) return;

  if (props.transitionType === 'fade') {
    // Set initial styles without transition
    currentStyle.value = { opacity: 1 };
    nextStyle.value = { opacity: 0 };

    // Use a short delay to apply transition
    setTimeout(() => {
      currentStyle.value = {
        opacity: 0,
        transition: `opacity ${transitionStyle.value}`
      };
      nextStyle.value = {
        opacity: 1,
        transition: `opacity ${transitionStyle.value}`
      };
    }, 50);
  } else if (props.transitionType === 'scroll') {
    // Set initial styles without transition
    currentStyle.value = { transform: 'translateX(0)' };
    nextStyle.value = { transform: 'translateX(100%)' };

    // Use a short delay to apply transition
    setTimeout(() => {
      currentStyle.value = {
        transform: 'translateX(-100%)',
        transition: `transform ${transitionStyle.value}`
      };
      nextStyle.value = {
        transform: 'translateX(0)',
        transition: `transform ${transitionStyle.value}`
      };
    }, 50);
  }
};

// Carousel control functions
const startCarousel = () => {
  if (!hasMultipleContents.value) return;

  interval.value = setInterval(() => {
    applyTransition();

    setTimeout(() => {
      // After the transition, update indices
      currentIndex.value = nextIndex.value;
      nextIndex.value = (currentIndex.value + 1) % contents.value.length;

      // Reset styles for the next transition
      if (props.transitionType === 'fade') {
        currentStyle.value = { opacity: 1 };
        nextStyle.value = { opacity: 0 };
      } else if (props.transitionType === 'scroll') {
        currentStyle.value = { transform: 'translateX(0)' };
        nextStyle.value = { transform: 'translateX(100%)' };
      }
    }, props.transitionDuration); // Use transitionDuration for timing
  }, props.transitionTime);
};

const stopCarousel = () => {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
};

// Lifecycle hooks
onMounted(startCarousel);
onUnmounted(stopCarousel);

watch(
  () => props.transitionTime,
  () => {
    stopCarousel();
    startCarousel();
  }
);
</script>

<style scoped>
.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Default styles */
.carousel-content.current {
  z-index: 2;
}

.carousel-content.next {
  z-index: 1;
}
</style>
