import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "layout",
  class: "relative h-[88%] w-full overflow-hidden"
}

import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { LayoutType } from "@/stores/settingsStore";
import { useLayoutStore } from "@/stores/layoutStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutComponent',
  setup(__props) {

const layoutStore = useLayoutStore();

const layoutType = computed(() => layoutStore.layoutType);
const layoutComponents = computed(() => layoutStore.layoutData);

const mainSectionClass = computed(() => {
  if (layoutType.value === LayoutType.HEADER_BODY_FOOTER) {
    return "main-section-center";
  } else if (layoutType.value === LayoutType.HEADER_BODY) {
    return "main-section-bottom";
  } else if (layoutType.value === LayoutType.BODY_FOOTER) {
    return "main-section-top";
  } else {
    return "main-section-full";
  }
});

onMounted(() => {
  layoutStore.setupEventListeners();
});

onBeforeUnmount(() => {
  layoutStore.removeEventListeners();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        layoutType.value === _unref(LayoutType).HEADER_BODY_FOOTER ||
        layoutType.value === _unref(LayoutType).HEADER_BODY
      )
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([
        'section',
        'top-section',
        { 'expanded-top': _unref(layoutStore).isTopExpanded },
      ])
        }, [
          _renderSlot(_ctx.$slots, "top"),
          (layoutComponents.value.HEADER)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(layoutComponents.value.HEADER.component), {
                key: 0,
                "layout-position": "top",
                "component-info": layoutComponents.value.HEADER.details
              }, null, 8, ["component-info"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
        'section',
        mainSectionClass.value,
        { 'expanded-center': _unref(layoutStore).isCenterExpanded },
      ])
    }, [
      (layoutComponents.value.BODY)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(layoutComponents.value.BODY.component), {
            key: 0,
            "layout-position": "center",
            "component-info": layoutComponents.value.BODY.details
          }, null, 8, ["component-info"]))
        : _createCommentVNode("", true)
    ], 2),
    (
        layoutType.value === _unref(LayoutType).HEADER_BODY_FOOTER ||
        layoutType.value === _unref(LayoutType).BODY_FOOTER
      )
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([
        'section',
        'bottom-section',
        { 'expanded-bottom': _unref(layoutStore).isBottomExpanded },
      ])
        }, [
          (layoutComponents.value.FOOTER)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(layoutComponents.value.FOOTER.component), {
                key: 0,
                "layout-position": "bottom",
                "component-info": layoutComponents.value.FOOTER.details
              }, null, 8, ["component-info"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})