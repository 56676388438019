<template>
  <div class="w-full h-full">
    <div class="flex flex-col h-full">
      <div class="bg-neutral-100 h-[56vw] relative">
        <template v-for="(img, index) in data.image" :key="index">
          <img
            :src="img?.url ? img.url : ''"
            class="w-full h-full object-cover"
          />
        </template>

        <div
          class="absolute w-full h-full p-[6vw] text-white top-0 right-0 flex flex-col bg-neutral-800/35"
        >
          <div
            class="border border-white rounded w-[14vw] h-[14vw] flex flex-col justify-center items-center ms-auto"
          >
            <template v-if="data?.date">
              <p class="text-[2.2vh] font-bold leading-tight mb-0">
                {{ getDay(data?.date) }}
              </p>
              <p class="text-[1.4vh] font-semibold">
                {{ getMonth(data?.date) }}
              </p>
            </template>
            <template v-else-if="data?.start_date && data?.end_date">
              <p class="text-[2.2vh] font-bold leading-tight mb-0">
                {{ data?.start_date ? getDay(data?.start_date) : "" }} -
                {{ data?.end_date ? getDay(data?.end_date) : "" }}
              </p>
              <p class="text-[1.4vh] font-semibold">
                {{ data?.start_date ? getMonth(data?.start_date) : "" }}
              </p>
            </template>
          </div>
          <h3 class="text-[3.8vh] font-bold leading-tight mt-auto">
            {{ data?.title }} 
          </h3>
        </div>
      </div>
      <div class="h-full relative">
        <div
          class="w-full h-[1.2vh] absolute z-10 top-0 left-0 bg-gradient-to-b from-black/20 to-transparent"
        ></div>
        <div class="h-full overflow-y-auto scrollbar-none">
          <!-- ABSTRACT -->
          <div
            class="gradient-gray p-[6vw] pb-[12vw] text-gray-theme text-[1.8vh] font-semibold"
          >
            {{ data?.abstract }}
          </div>
          <div
            class="rounded-t-3xl mt-[-4vh] bg-white pt-[6vw] pb-[35vh] shadow-top"
          >
            <!-- QR CODE -->
            <div class="ps-[6vw] pe-[8vw] pt-[2vw] pb-[2vw]">
              <div
                class="relative gradient-gray rounded-3xl p-[4vw] pe-[21vw] text-gray-theme flex gap-[4vw] items-center"
              >
                <img src="@/assets/images/qr-tel.png" class="w-[10vw]" />
                <div class="leading-tight">
                  <h4 class="text-[2.8vh] font-bold">Portami con te!</h4>
                  <p class="text-[1.8vh] font-semibold mt-1">
                    Scansiona il codice QR e leggi questa pagina sul tuo
                    smartphone
                  </p>
                </div>
                <div
                  class="absolute top-[-2vw] right-[-2vw] rounded-xl shadow-center bg-white p-[2vw]"
                >
                <img :src="'data:image/png;base64,' + data?.qr_code" alt="QR Code" class="w-[16vw]"/>

                </div>
              </div>
            </div>
            <!-- TESTO -->
            <div class="serif p-[6vw]">
              <div class="text-[1.6vh] leading-relaxed space-y-6">
                <p v-html="data?.body" />
              </div>
            </div>
            <!-- ALLEGATI -->
            <div class="px-[6vw] pb-[5vh]" v-if="data?.files?.length>0">
                <PdfViewerComponent 
                  :pdf-list="pdfList"
                  :fullScreen="isPdfFullScreen"
                  @pdfEnterFullScreen="pdfEnterFullScreen"
                  @pdfExitFullScreen="pdfExitFullScreen"
                />
            </div>
            <!-- PLACEHOLDER PER MAPPA  -->
            <template v-if="data?.position">
              <MapComponent
              :latitude="data.position?.latitude"  
              :address="data.position?.address"
              :longitude="data.position?.longitude"
                :fullScreen="isMapFullScreen"
                @mapEnterFullScreen="mapEnterFullScreen"
                @mapExitFullScreen="mapExitFullScreen"
              />
            </template>
            <!-- GALLERY -->
            <template v-if="images?.length > 0">
              <GalleryComponent :images="images"
              :fullScreen="isGalleryFullScreen"
              @galleryEnterFullScreen="galleryEnterFullScreen"
              @galleryExitFullScreen="galleryExitFullScreen"
               />
            
            </template>
            <!-- PLACEHOLDER PER VIDEO -->
            <!-- <template v-for="(video, index) in data?.videos" :key="index" v-if="data?.videos.length > 0">
              <video class="w-[120%] h-[46vw]" controls>
                <source :src="video.url" />
              </video>
            </template> -->
            <!-- Correlati -->
            <!-- <h4
              class="text-gray-theme text-[1.8vh] font-bold leading-tight uppercase ms-12 mb-5"
            >
              Leggi anche
            </h4>
            <div
              class="flex flex-col gap-[1.5vw] h-full overflow-y-auto scrollbar-none"
            >
              <div class="bg-neutral-100 h-[25vw] relative">
                <img
                  src="https://picsum.photos/200/300"
                  class="w-full h-full object-cover"
                />
                <div
                  class="absolute w-full h-full p-[4.5vw] text-white top-0 right-0 flex items-center bg-neutral-800/35"
                >
                  <div class="leading-tight">
                    <h3 class="text-[2.2vh] font-bold">Titolo allegato</h3>
                    <p class="text-[1.2vh] font-semibold mt-[1vw]">
                      Abstract allegato
                    </p>
                  </div>
                  <div
                    class="border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center mb-auto ms-auto"
                  >
                    <p class="text-[1.8vh] font-bold leading-tight mb-0">
                      24-27
                    </p>
                    <p class="text-[1vh] font-semibold">Novembre</p>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref, computed, watch } from "vue";
import { Icon } from "@iconify/vue";
import { getMonth } from "@/helpers/helpers";
import { getDay } from "@/helpers/helpers";
import MapComponent from "@/components/MapComponent.vue";
import { useLayoutStore } from "@/stores/layoutStore";
import PdfViewerComponent from "./PdfViewerComponent.vue";
import GalleryComponent from "./GalleryComponent.vue";
import router from "@/router";

const layoutStore = useLayoutStore();

const props = defineProps<{
  data: any;
}>();

//aggiungere tutte le condizioni in or

const isMapFullScreen = ref(false);
const isGalleryFullScreen = ref(false);
const isPdfFullScreen = ref(false);

const isLayoutBusy = computed(() => isMapFullScreen.value || isGalleryFullScreen.value || isPdfFullScreen.value);
watch(isLayoutBusy, (newValue) => {
  layoutStore.isLayoutBusy = newValue;
});

const images = computed(() => {
  return props.data?.gallery?.map((image: any) => image.url) || [];
})

const pdfList = computed(() => {
  if (props.data?.files?.length>0)
    return props.data?.files|| [];
  else return [];
})
console.log(props.data, "data detail");

const mapEnterFullScreen = () => {
  isMapFullScreen.value = true;
};

const mapExitFullScreen = () => {
  isMapFullScreen.value = false;
};

const galleryEnterFullScreen = () => {
  isGalleryFullScreen.value = true;
};
const galleryExitFullScreen = () => {
  isGalleryFullScreen.value = false;
};

const pdfEnterFullScreen = () => {
  isPdfFullScreen.value = true;
};
const pdfExitFullScreen = () => {
  isPdfFullScreen.value = false;
};

const handleGoBack = () => {
  if (!!isMapFullScreen.value) mapExitFullScreen();
  else if (!!isGalleryFullScreen.value) galleryExitFullScreen();
  else if (!!isPdfFullScreen.value) pdfExitFullScreen();
};

onMounted(() => {
  window.addEventListener("navigation:goBack", handleGoBack);
});

onBeforeUnmount(() => {
  window.removeEventListener("navigation:goBack", handleGoBack);
});


const goToActivityPage = () => {
  if (props.data.id) {
    // Naviga alla rotta dinamica con l'ID dell'attività
    router.push({ name: 'Activity', params: { id: props.data.id } });
  }
};
</script>
