<template>
  <div
    class="h-[6%] bg-white px-5 flex iteems-center justify-between border-b border-neutral-300"
  >
    <!-- Left Side: Logo and City Name -->
    <div class="flex items-center gap-5">
      <img :src="logo ?? ''" alt="City Logo" class="w-[3.2vh]" />
      <div class="flex flex-col justify-items-start">
        <span class="text-[1.6vh] text-left leading-[1]">Comune di</span>
        <span class="text-[2.2vh] text-left font-semibold leading-[1]">{{
          cityName
        }}</span>
      </div>
    </div>

    <!-- Right Side: Date, Time, and Language Selector -->
    <div class="flex items-center gap-5">
      <div class="flex flex-col">
        <span class="text-[1.6vh] text-right leading-[1]">{{
          formattedDate
        }}</span>
        <span class="text-[2.2vh] text-right font-semibold leading-[1]">{{
          currentTime
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useSettingsStore } from '@/stores/settingsStore';

const settingsStore = useSettingsStore();
const logo = computed(() => settingsStore.logo);
const cityName = computed(() => settingsStore.scenarioName);

const currentTime = ref('');
const formattedDate = ref('');

// Function to update date and time
const updateDateTime = () => {
  const now = new Date();
  formattedDate.value = now.toLocaleDateString('it-IT', {
    weekday: 'long',
    day: 'numeric',
    month: 'long'
  });
  currentTime.value = now.toLocaleTimeString('it-IT', {
    hour: '2-digit',
    minute: '2-digit'
  });
};

onMounted(() => {
  updateDateTime();
  const interval = setInterval(updateDateTime, 1000); // Update every minute

  // Clear interval when component is unmounted
  onUnmounted(() => {
    clearInterval(interval);
  });
});
</script>
