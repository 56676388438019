import { defineStore } from "pinia";
import AxiosClient from "@/Axios/AxiosClient";

interface AlertData {
  generalState: string;
  date: string;
  expires: string;
  risks: {
    idrogeologica: string;
    idraulica: string;
    temporali: string;
    neve: string;
    valanghe: string;
  };
}

interface ProtezioneCivileStoreState {
  todayAlert: AlertData | null;
  tomorrowAlert: AlertData | null;
  lastUpdated: number | null;
  error: string | null;
}

export const useProtezioneCivileStore = defineStore("protezioneCivile", {
  state: (): ProtezioneCivileStoreState => ({
    todayAlert: null,
    tomorrowAlert: null,
    lastUpdated: null,
    error: null,
  }),

  actions: {
    async fetchAlertData(region: string) {
      const now = Date.now();

      // Use cached data if it is still valid (within 24 hours)
      if (this.lastUpdated && now - this.lastUpdated < 24 * 60 * 60 * 1000) {
        return; // Cached data is still valid, no need to fetch
      }
      try {
        const response = await AxiosClient.get(`/protezione-civile/${region}`);
        this.todayAlert = response.data.todayAlert;
        this.tomorrowAlert = response.data.tomorrowAlert;
        this.lastUpdated = now; // Update the cache timestamp
        this.error = null; // Clear any previous errors
        console.log("tomorrowAlert", this.tomorrowAlert);
        console.log("todayAlert", this.todayAlert);
      } catch (error) {
        this.error = "Failed to retrieve alerts";
        console.error(error);
      }
    },

    getColorDescription(color: string): string {
      const colorDescriptions: { [key: string]: string } = {
        green: "Criticità assente",
        yellow: "Criticità ordinaria",
        orange: "Criticità moderata",
        red: "Criticità elevata",
      };

      return colorDescriptions[color] || "Nessuna previsione";
    },

    // Initializes periodic data refresh every 24 hours
    startAutoRefresh(region: string) {
      this.fetchAlertData(region); // Initial fetch
      setInterval(() => {
        this.fetchAlertData(region);
      }, 24 * 60 * 60 * 1000); // Refresh every 24 hours
    },
  },

  getters: {
    isDataStale(): boolean {
      return (
        !this.lastUpdated ||
        Date.now() - this.lastUpdated >= 24 * 60 * 60 * 1000
      );
    },
  },
});
