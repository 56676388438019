import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "carousel-container h-full" }

import { computed, DefineComponent } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';


export default /*@__PURE__*/_defineComponent({
  __name: 'Carousel',
  props: {
    tipoScroll: {},
    items: {},
    duration: {}
  },
  setup(__props: any) {

const props = __props;
const tipoScroll = computed(() => props.tipoScroll || 'slide');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Swiper), {
      spaceBetween: 1,
      loop: true,
      autoplay: { delay: _ctx.duration, disableOnInteraction: false },
      effect: tipoScroll.value,
      modules: [_unref(Autoplay), _unref(EffectFade), _unref(Pagination), _unref(Navigation)],
      class: "mySwiper h-full"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createBlock(_unref(SwiperSlide), {
            key: index,
            class: "h-full"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), _mergeProps({ ref_for: true }, item.props, { class: "carousel-item" }), null, 16))
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["autoplay", "effect", "modules"])
  ]))
}
}

})