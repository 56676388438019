<template>
  <div
    v-if="!isFullScreen"
    class="gradient-gray rounded-3xl p-[4vw] text-gray-theme flex flex-col gap-5"
  >
    <template template v-if="pdfList && pdfList.length" v-for="(element, i) in pdfList"  :key="i">
      <div  
        class="flex gap-5 items-center text-[1.8vh] font-semibold leading-tight bg-red-200 cursor-pointer"
        :class="isFullScreen ? 'bg-green-200' : 'bg-yellow-200'"
        @click="openPdf(i)"
      >
        <Icon icon="ci:link" class="w-[4vw] h-[4vw]" />
        <span class="flex-1">{{ element.file_name }}</span>
      </div>
      <div>
        {{debugText}}
      </div>
    </template>
  </div>

  <!-- <div class="pdf-viewer full-screen" v-if="isFullScreen">
    <div v-if="loading" class="spinner-container">
      <div class="spinner"></div>
    </div>

    <div class="pdf-pages" :style="{ opacity: loading ? 0 : 1 }">
      <div v-for="page in pages" :key="page" class="pdf-page">
        <VuePDF :pdf="pdf" :page="page" :scale="1.7" @loaded="onPageLoaded" />
      </div>
    </div>
  </div> -->
  <div class="pdf-viewer full-screen" v-if="isFullScreen">
    <iframe
      :src="props.pdfList[currentIndex].url"
      class="pdf-iframe h-full w-full"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { VuePDF, usePDF } from "@tato30/vue-pdf";
import { Icon } from "@iconify/vue";

const props = defineProps<{
  pdfList: {file_name: string, url: string}[];
  fullScreen: boolean;
}>();
const emit = defineEmits(["pdfEnterFullScreen", "pdfExitFullScreen"]);
const isFullScreen = computed(() => props.fullScreen);
const loading = ref(true);
const debugText = ref("");
const currentIndex = ref(0);

let loadedPages = ref(0);

const onPageLoaded = () => {
  loadedPages.value += 1;

  if (loadedPages.value === pages.value) {
    loading.value = false;
  }
};

let { pdf, pages } = { pdf: null, pages: null };

const openPdf = (index: number) => {
  debugText.value = "Click received on index " + index;
  // ({ pdf, pages } = usePDF(props.pdfList[index] || ""));
  currentIndex.value = index;
  emit("pdfEnterFullScreen");
  debugText.value = "opening" + props.pdfList[index].file_name;

  watch(pages, (newPageCount) => {
    loadedPages.value = 0;
    loading.value = true;
  });
};
</script>

<style scoped>
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
}

.pdf-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px 0;
  box-sizing: border-box;
}

.pdf-pages {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  transition: opacity 0.3s ease;
}

.pdf-page {
  display: flex;
  justify-content: center;
  width: 100%;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
