export function getDay(data: string): string {
  // Estrae solo il giorno dalla data (che è la parte finale della stringa)
  return data.split("-")[2];
}

export function getMonth(data: string): string {
  const mesiInItaliano = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  // Estrae il numero del mese dalla data (che è la seconda parte della stringa)
  const meseNumero = parseInt(data.split("-")[1], 10);

  // Restituisce il mese corrispondente dall'array (meseNumero - 1 perché l'indice parte da 0)
  return mesiInItaliano[meseNumero - 1];
}

export function getThinMonth(data: string): string {
  const mesiInItaliano = [
    "Gen",
    "Feb",
    "Mar",
    "Apr",
    "Mag",
    "Giu",
    "Lug",
    "Ago",
    "Set",
    "Ott",
    "Nov",
    "Dic",
  ];

  // Estrae il numero del mese dalla data (che è la seconda parte della stringa)
  const meseNumero = parseInt(data.split("-")[1], 10);

  // Restituisce il mese corrispondente dall'array (meseNumero - 1 perché l'indice parte da 0)
  return mesiInItaliano[meseNumero - 1];
}
