<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Livello 1"
    viewBox="0 0 50 50"
    fill="currentColor"
  >
    <path
      d="M37.31 15.23a12.48 12.48 0 0 0-3.9-6.72 12.559 12.559 0 0 0-5.15-2.78c-1.91-.51-3.92-.56-5.85-.14-1.93.41-3.74 1.28-5.28 2.53a12.646 12.646 0 0 0-3.56 4.64c-4.59.27-8.24 4.08-8.24 8.73s3.93 8.75 8.76 8.75h5.44l-1.06 6.35a1.319 1.319 0 0 0 .29 1.06c.12.15.28.26.45.34.17.08.36.12.55.12h2.62v5.25a1.312 1.312 0 0 0 .91 1.25c.26.08.55.08.81 0 .26-.09.49-.26.65-.48l6.56-9.19c.14-.2.22-.43.24-.67.02-.24-.03-.48-.14-.7a1.41 1.41 0 0 0-.48-.52c-.21-.13-.44-.19-.68-.19h-2.26l.66-2.62h8.52c1.98 0 3.87-.79 5.27-2.19s2.19-3.28 2.21-5.26c.02-1.98-.75-3.88-2.12-5.3a7.554 7.554 0 0 0-5.23-2.28Z"
    />
  </svg>
</template>
