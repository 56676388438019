<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Livello 1"
    viewBox="0 0 50 50"
    fill="currentColor"
  >
    <path
      d="M25 45.5c8.49 0 15.38-5.12 15.38-14.09 0-3.84-1.28-10.25-6.41-15.38.64 3.84-3.2 5.13-3.2 5.13 1.92-6.41-3.2-15.38-10.89-16.66.91 5.13 1.28 10.25-5.12 15.37-3.2 2.56-5.12 6.99-5.12 11.53 0 8.97 6.88 14.09 15.38 14.09Zm0-2.56c-4.25 0-7.69-2.56-7.69-7.05 0-1.92.64-5.12 3.2-7.69-.32 1.92 1.92 3.2 1.92 3.2-.96-3.2 1.28-8.33 5.12-8.97-.46 2.56-.64 5.12 2.56 7.69 1.6 1.28 2.56 3.5 2.56 5.77 0 4.48-3.44 7.05-7.69 7.05Z"
    />
  </svg>
</template>
