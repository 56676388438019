import { defineStore } from 'pinia';
import { useSettingsStore } from './settingsStore';
import { useLayoutStore } from './layoutStore';

export const useMainStore = defineStore('main', {
  state: () => ({
    isLoading: false,
    hasError: false,
    isStandbyVisible: false,
    isStandByActive: false,
    standbyTimeout: null as number | null,
    standbyTimer: null as ReturnType<typeof setTimeout> | null
  }),

  actions: {
    // Step 1: Recover the token
    async initializeApp() {
      console.log('Initializing app...');
      // Step 2: Load settings and show loading
      this.isLoading = true;
      await this.loadSettings();
    },

    // Step 3: Load settings through the settingsStore
    async loadSettings() {
      console.log('Loading settings...');
      const settingsStore = useSettingsStore();
      const layoutStore = useLayoutStore();

      try {
        await settingsStore.fetchConfiguration();
        this.isStandByActive = settingsStore.standByActive;
        if (this.isStandByActive) {
          this.isStandbyVisible = true;
          this.standbyTimeout = settingsStore.standbyTime; // Get timeout from settings
          this.startStandbyTimer(); // Start the standby timer
        }
        
        this.isLoading = false; // Stop loading once settings are loaded
      } catch (error) {
        this.hasError = true;
        console.error('Failed to load settings:', error);
      }
      //passo il tipo di layout al layout store
      layoutStore.layoutType = settingsStore.layoutType;
      try {
        await layoutStore.fetchPlayables();
        
        this.isLoading = false; // Stop loading once settings are loaded
      } catch (error) {
        this.hasError = true;
        console.error('Failed to load settings:', error);
      }

    },

    // Step 4: Handle standby view based on user interaction and timeout
    toggleStandbyVisibility(visible: boolean) {
      if (!this.isStandByActive) return;
      this.isStandbyVisible = visible;
      if (visible) {
        this.clearStandbyTimer();
        
      } else {
        this.startStandbyTimer();
      }
    },

    startStandbyTimer() {
      if (!this.isStandByActive) return;
      // Clear any existing timer
      if (this.standbyTimer) clearTimeout(this.standbyTimer);

      // Start new timer based on standbyTimeout from settings
      this.standbyTimer = setTimeout(() => {
        this.isStandbyVisible = true; // Show standby view after timeout
        useLayoutStore().homeEventReceived()
      }, this.standbyTimeout || 60000); // Default to 60s if standbyTimeout is missing
    },

    clearStandbyTimer() {
      if (!this.isStandByActive) return;
      if (this.standbyTimer) {
        clearTimeout(this.standbyTimer);
        this.standbyTimer = null;
      }
    },

    emitEvent(event: string) {
      window.dispatchEvent(new CustomEvent(event));
    },

    resetError() {
      this.hasError = false;
    }
  }
});
