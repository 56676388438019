import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import App from '../App.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'totem',
    component: App
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/activities/:id', // La rotta dinamica
    name: 'Activity',
    component: () => import('../views/ActivityView.vue'),
    props: true, // Passa l'ID come prop
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
