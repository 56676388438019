<template>
  <div class="carousel-container h-full">
    <swiper
      :spaceBetween="1"
      :loop="true"
      :autoplay="{ delay: duration, disableOnInteraction: false }"
      :effect="tipoScroll"
      :modules="[Autoplay, EffectFade, Pagination, Navigation]"
      class="mySwiper h-full"
    >
      <swiper-slide
        v-for="(item, index) in items"
        :key="index"
        class="h-full"
      >
        <component :is="item.component" v-bind="item.props" class="carousel-item" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import { computed, DefineComponent, defineProps } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';

const props = defineProps<{
  tipoScroll?: 'slide' | 'fade' | 'cube' | 'coverflow';
  items: Array<{
    component: any | DefineComponent;
    props: { [key: string]: any };
  }>;
  duration: number;
}>();
const tipoScroll = computed(() => props.tipoScroll || 'slide');

</script>

<style scoped>
.carousel-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.carousel-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease;
}
</style>
