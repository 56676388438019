import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './assets/main.scss';
import VueGoogleMaps from '@fawmi/vue-google-maps';

const app = createApp(App);

app.use(router);
app.use(createPinia());
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCMQe6lO-x3pMPVNe9OqOsZxbytUfKO1JA', // Remplacez par votre clé
        libraries: 'places',
        region: 'IT',
        language: 'it'
    }
})

app.mount('#app');
