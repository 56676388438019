<template>
  <div class="h-full">
    <Carousel
      :tipoScroll="componentInfo?.transition"
      :items="imageComponents"
      :duration="duration"
      class="h-full"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, onMounted } from 'vue';
import Carousel from './Carousel.vue';
import ImageContent from '@/components/ImageContent.vue';

interface ComponentInfo {
  transition: 'slide' | 'fade' | 'cube' | 'coverflow';  // Swiper supported effects
  duration: number;
  images: { url: string }[];
}

const props = defineProps<{
  layoutPosition: 'top' | 'center' | 'bottom';
  componentInfo: ComponentInfo | null;
}>();

const duration = computed(() => (props.componentInfo?.duration || 5) * 1000);
const imageComponents = computed(() => {
  return (
    props.componentInfo?.images.map((image) => ({
      component: ImageContent,
      props: { src: image.url },
    })) || []
  );
});

onMounted(() => {
  console.log('ImagePlayable mounted', props.componentInfo);
});
</script>

<style scoped>
/* Any parent component specific styles */
</style>
