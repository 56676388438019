<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Livello 1"
    viewBox="0 0 50 50"
    fill="currentColor"
  >
    <path
      d="M37.79 43.62c-1.88 0-3.11.32-3.69.96-.58.64-1.47.96-2.68.96-1.15 0-2.05-.32-2.71-.96-.66-.64-1.89-.96-3.71-.96s-3.11.32-3.69.96-1.47.96-2.68.96-2.11-.32-2.68-.96-1.81-.96-3.69-.96-3.12.32-3.71.96-1.49.96-2.71.96v3.83c1.88 0 3.13-.32 3.74-.96.61-.64 1.5-.96 2.68-.96s2.11.32 2.68.96c.58.64 1.8.96 3.69.96s3.11-.32 3.69-.96c.58-.64 1.47-.96 2.68-.96s2.09.32 2.71.96c.62.64 1.86.96 3.71.96s3.08-.32 3.69-.96c.61-.64 1.5-.96 2.68-.96s2.08.32 2.68.96c.61.64 1.84.96 3.69.96v-3.83c-1.21 0-2.11-.32-2.68-.96-.58-.64-1.8-.96-3.69-.96ZM41.48 36.58c-.58-.64-1.8-.96-3.69-.96s-3.11.32-3.69.96c-.58.64-1.47.96-2.68.96-1.15 0-2.05-.32-2.71-.96-.66-.64-1.89-.96-3.71-.96s-3.11.32-3.69.96-1.47.96-2.68.96-2.11-.32-2.68-.96-1.81-.96-3.69-.96-3.12.32-3.71.96-1.49.96-2.71.96v3.83c1.88 0 3.13-.32 3.74-.96.61-.64 1.5-.96 2.68-.96s2.11.32 2.68.96c.58.64 1.8.96 3.69.96s3.11-.32 3.69-.96c.58-.64 1.47-.96 2.68-.96s2.09.32 2.71.96c.62.64 1.86.96 3.71.96s3.08-.32 3.69-.96c.61-.64 1.5-.96 2.68-.96s2.08.32 2.68.96c.61.64 1.84.96 3.69.96v-3.83c-1.21 0-2.11-.32-2.68-.96ZM27.17 19.2l3.81 3.1 6.85-3.1v-6.19l-6.85-1.55-3.81 3.1v4.64zM26.36 21.97l-3.21-1.3-8.17-7.66H5.83v18.61h30.48l-9.95-9.65zM28.69 9.91V2.17L21.07.62l-4.57 3.1v6.19l4.57 3.1 7.62-3.1z"
    />
  </svg>
</template>
