import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]



export default /*@__PURE__*/_defineComponent({
  __name: 'ImageContent',
  props: {
    src: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", {
    src: _ctx.src,
    alt: "carousel image"
  }, null, 8, _hoisted_1))
}
}

})