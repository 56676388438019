<template>
  <div
    :class="[{ expanded: isExpanded }, 'h-full']"
    @click="layoutStore.requestFullScreen(props.layoutPosition)"
  >
    <div
      :class="{
        'h-full relative cursor-pointer transition-all duration-500 ease-in-out': true,
        'bg-white p-[6vw] h-full w-full absolute top-0 left-0 overflow-y-auto scrollbar-none':
          isExpanded,
        [generalState + ' h-full w-full px-[6vw] py-[2vw] text-white']:
          !isExpanded,
      }"
    >
      <div v-if="!isExpanded" class="w-full h-full flex gap-[3.5vw] items-center">
        <img src="@/assets/images/logo-prociv.png" class="w-[18vw]" />
        <div class="w-full text-[1.8vh] font-semibold">
          <h2 class="text-[2.8vh] font-bold leading-tight mb-[1.2vw]">
            Allerte di Protezione Civile
          </h2>
          <div class="flex mb-[1.2vw]">
            <div class="flex items-center gap-1">
              <!-- <ProcivIdrogeologico class="w-[5vw] h-[5vw]" />
              <span>Idraulico</span> -->
            </div>
            <div class="flex items-center gap-1 ms-[3vh]">
              <!-- <ProcivTemporali class="w-[5vw] h-[5vw]" />
              <span>Temporali forti</span> -->
            </div>
          </div>
          <div class="w-full flex justify-between">
            <div class="text-[1.6vh] font-regular">
              {{ generalStateDescription }}
            </div>
            <a class="flex items-center gap-1"
              ><span class="text-[1.6vh] font-semibold">Leggi tutto</span
              ><Icon icon="si:arrow-right-duotone" class="w-[4vw] h-[4vw]"
            /></a>
          </div>
        </div>
      </div>
      <div v-else class="min-h-full flex flex-col justify-around">
        <div>
          <div class="flex gap-[1.5vw] mb-[.1vh]">
            <img src="@/assets/images/logo-prociv.png" class="w-[20vw]" />
            <h2 class="text-[3.8vh] font-bold leading-tight">
              Allerte di Protezione Civile
            </h2>
          </div>
          <p class="text-[1.8vh] font-semibold text-gray-theme leading-tight">
            Il servizio espone i dati emessi dalla Protezione Civile di Regione
            Lombardia per i livelli di allerta dei rischi naturali.
          </p>
        </div>
        <div class="mt-[1.8vh]">
          <div
            class="gradient-gray flex gap-3 items-center px-[2vw] py-[1vw] text-gray-theme text-[1.8vh] font-bold leading-tight"
          >
            <div class="w-[60%]"><h4 class="uppercase">Allerta</h4></div>
            <div class="w-[20%]">
              <h4 class="uppercase leading-none">Oggi</h4>
              <span class="text-[1.4vh] font-medium leading-none">
                {{ todayDate }}</span
              >
            </div>
            <div class="w-[20%]">
              <h4 class="uppercase leading-none">Domani</h4>
              <span class="text-[1.4vh] font-medium leading-none">{{
                tomorrowDate
              }}</span>
            </div>
          </div>
          <div
            class="flex gap-3 items-center px-[2vw] py-[1vw] border-b last:border-none border-neutral-400"
            v-for="(alert, index) in alertDetails"
            :key="index"
          >
            <div class="w-[60%] flex items-center gap-[1.5vw]">
              <component
                :is="alert.iconComponent"
                class="w-[5vw] h-[5vw] text-gray-theme-200"
              />
              <span class="text-[1.8vh] font-semibold">{{ alert.type }}</span>
            </div>
            <div class="w-[20%] text-center">
              <span :class="['indicator', alert.today]"></span>
            </div>
            <div class="w-[20%] text-center">
              <span :class="['indicator', alert.tomorrow]"></span>
            </div>
          </div>
        </div>
        <div class="mt-[2.2vh]">
          <p
            class="text-gray-theme text-[1.8vh] font-bold leading-tight uppercase ms-8 mb-3"
          >
            Legenda:
          </p>
          <div
            class="gradient-gray flex flex-col gap-1 px-[3vw] py-[2vw] text-[1.8vh]"
          >
            <div class="flex items-center gap-5">
              <span class="indicator none"></span> Nessuna previsione
            </div>
            <div class="flex items-center gap-5">
              <span class="indicator green"></span>
              <p>
                <span class="font-bold">Codice Verde</span> - Criticità assente
              </p>
            </div>
            <div class="flex items-center gap-5">
              <span class="indicator yellow"></span>
              <p>
                <span class="font-bold">Codice Giallo</span> - Criticità
                ordinaria
              </p>
            </div>
            <div class="flex items-center gap-5">
              <span class="indicator orange"></span>
              <p>
                <span class="font-bold">Codice Arancione</span> - Criticità
                moderata
              </p>
            </div>
            <div class="flex items-center gap-5">
              <span class="indicator red"></span>
              <p>
                <span class="font-bold">Codice Rosso</span> - Criticità elevata
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, ref, onMounted } from "vue";
import { useLayoutStore } from "@/stores/layoutStore";
import { Icon } from "@iconify/vue";
import ProcivIdrogeologico from "@/assets/icons/ProcivIdrogeologico.vue";
import ProcivVenti from "@/assets/icons/ProcivVenti.vue";
import ProcivIdraulico from "@/assets/icons/ProcivIdraulico.vue";
import ProcivValanghe from "@/assets/icons/ProcivValanghe.vue";

import ProcivNeve from "@/assets/icons/ProcivNeve.vue";

import ProcivTemporali from "@/assets/icons/ProcivTemporali.vue";
import { useProtezioneCivileStore } from "@/stores/protezioneCivileStore";

interface ComponentInfo {
  region: {
    name: string;
  };
}

const props = defineProps<{
  layoutPosition: "top" | "center" | "bottom";
  componentInfo: ComponentInfo | null;
}>();

const isExpanded = computed(() => {
  return props.layoutPosition == "center" || layoutStore.isSectionExpanded(props.layoutPosition);
});
const layoutStore = useLayoutStore();
const protezioneCivileStore = useProtezioneCivileStore();

const isLoading = ref(true); // Loading state

onMounted(async () => {
  const region = props.componentInfo?.region?.name.toLowerCase() || "piemonte";
  await protezioneCivileStore.fetchAlertData(region);
  isLoading.value = false; // Set loading to false once data is fetched
  console.log("protezione civile component info", props.componentInfo);
});

const generalState = computed(
  () => protezioneCivileStore.todayAlert?.generalState || "N/A"
);
const generalStateDescription = computed(() =>
  protezioneCivileStore.getColorDescription(generalState.value)
);
const todayDate = computed(() => protezioneCivileStore.todayAlert?.date || "");
const tomorrowDate = computed(
  () => protezioneCivileStore.tomorrowAlert?.date || ""
);

// Map alert details to display categories
const alertDetails = computed(() => [
  {
    type: "Idrogeologico",
    today: protezioneCivileStore.todayAlert?.risks.idrogeologica || "none",
    tomorrow:
      protezioneCivileStore.tomorrowAlert?.risks.idrogeologica || "none",
    iconComponent: ProcivIdrogeologico,
  },
  {
    type: "Idraulico",
    today: protezioneCivileStore.todayAlert?.risks.idraulica || "none",
    tomorrow: protezioneCivileStore.tomorrowAlert?.risks.idraulica || "none",
    iconComponent: ProcivIdraulico,
  },
  {
    type: "Temporali forti",
    today: protezioneCivileStore.todayAlert?.risks.temporali || "none",
    tomorrow: protezioneCivileStore.tomorrowAlert?.risks.temporali || "none",
    iconComponent: ProcivTemporali,
  },
  {
    type: "Neve",
    today: protezioneCivileStore.todayAlert?.risks.neve || "none",
    tomorrow: protezioneCivileStore.tomorrowAlert?.risks.neve || "none",
    iconComponent: ProcivNeve,
  },
  {
    type: "Valanghe",
    today: protezioneCivileStore.todayAlert?.risks.valanghe || "none",
    tomorrow: protezioneCivileStore.tomorrowAlert?.risks.valanghe || "none",
    iconComponent: ProcivValanghe,
  },
]);
</script>

<style scoped>
.indicator {
  width: 2vh;
  height: 2vh;
  border-radius: 50%;
  display: inline-block;
}
.green {
  background-color: #4caf50;
}
.yellow {
  background-color: #ffeb3b;
}
.orange {
  background-color: #ff9800;
}
.red {
  background-color: #f44336;
}
.none {
  background-color: #ccc;
}
</style>
