<template>
  <div :class="['custom-autocomplete mb-[5vh]', { 'full-screen': isFullScreen }]">
    <GMapMap
      ref="mapRef"
      :center="center"
      :zoom="zoom"
      :options="mapOptions"
      map-type-id="terrain"
      :style="mapStyles"
      @tilesloaded="hideDefaultFullScreenButton"
    >
      <GMapMarker :position="center" :title="address" />
    </GMapMap>
    <!-- Custom Full-Screen Toggle Button -->
    <button @click="toggleFullScreen" class="custom-fullscreen-btn absolute top-[2vw] right-[2vw] shadow bg-white border border-neutral-300 p-[1vw]">
      <template v-if="isFullScreen">
        <Icon icon="ic:baseline-zoom-in-map" class="w-[4vw] h-[4vw]" />
      </template>
      <template v-else>
        <Icon icon="ic:baseline-zoom-out-map" class="w-[4vw] h-[4vw]" />
      </template>
    </button>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  reactive,
  watch,
  onMounted,
  withDefaults,
  defineProps,
  computed,
} from "vue";
import { Icon } from "@iconify/vue";

// Define props with default values
const props = withDefaults(
  defineProps<{
    latitude: number;
    longitude: number;
    address: string;
    fullScreen: boolean;
  }>(),
  {
    latitude: 44.494887,
    longitude: 11.3426163,
    address: "",
    fullScreen: false,
  }
);

// Define emits
const emit = defineEmits(["mapEnterFullScreen", "mapExitFullScreen"]);

// Map settings
const zoom = ref(16);
const center :any = computed(() => ({
  lat: props.latitude,
  lng: props.longitude,
}));

// Full-screen control
const isFullScreen = computed(() => props.fullScreen);
const toggleFullScreen = () => {
  emit(isFullScreen.value ? "mapExitFullScreen" : "mapEnterFullScreen");
};

// Map styles based on full-screen status
const mapStyles = computed(() => ({
  width: "100%",
  height: isFullScreen.value ? "100vh" : "46vw",
}));

// Hide controls
const mapOptions = {
  zoomControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
};

// Hide Google Maps default full-screen button
const hideDefaultFullScreenButton = () => {
  const attemptHide = () => {
    const mapRef = document.querySelector(".gm-fullscreen-control") as HTMLElement;
    if (mapRef) {
      mapRef.style.display = "none";
    } else {
      // Retry if the button is not yet available
      setTimeout(attemptHide, 100);
    }
  };
  attemptHide();
};

// Watch for prop changes in latitude and longitude
watch(
  () => [props.latitude, props.longitude],
  ([newLatitude, newLongitude]) => {
    center.value = { lat: newLatitude, lng: newLongitude };
  }
);
</script>

<style scoped>
.custom-autocomplete {
  width: 100%;
  position: relative;
  transition: all 0.3s;
}

.custom-autocomplete.full-screen {
  position: fixed;
  top: var(--navbar-height, 0); /* Adjust as per navbar height */
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 1000;
}

.custom-fullscreen-btn {
  z-index: 500;
  cursor: pointer;
}

.gmap-map {
  border-radius: 10px;
  overflow: hidden;
}
</style>
