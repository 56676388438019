<template>
  <div class="h-full bg-white">
    <template v-if="viewMode === 'grid'">
      <!-- Grid View -->
      <div
        class="grid grid-cols-2 gap-[1.5vw] max-h-full overflow-y-auto scrollbar-none"
      >
        <template v-for="(news, index) in newsList" :key="index">
          <div
            class="bg-neutral-100 aspect-square relative"
            @click="openDetail(news)"
          >
            <div class="bg-neutral-100 h-[49vw] relative">
              <img
                :src="
                  news?.image
                    ? news.image[0]?.url
                    : 'https://picsum.photos/200/300'
                "
                class="w-full h-full object-cover"
              />
              <div
                class="absolute w-full h-full p-[4.5vw] text-white top-0 right-0 flex flex-col bg-neutral-800/35"
              >
                <div
                  class="border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center ms-auto"
                >
               
                  <template v-if="news?.date">
              <p class="text-[1.8vh] font-bold leading-tight mb-0">
                {{ getDay(news?.date) }}
              </p>
              <p class="text-[1vh] font-semibold">
                {{ getMonth(news?.date) }}
              </p>
            </template>
            <template v-else>
              <p class="text-[1.8vh] font-bold leading-tight mb-0">
                {{ getDateEvent(news?.start_date,news?.end_date) }}
              </p>
              <p class="text-[1vh] font-semibold">
                {{ getMonthEvent(news?.start_date ,news.end_date) }}

              </p>
            </template>
                </div>
                <div class="leading-tight mt-auto">
                  <h3 class="text-[2.2vh] font-bold">
                    {{ news?.title }}
                  </h3>
                  <p class="text-[1.2vh] font-semibold mt-[1vw]">
                    {{ news?.abstract }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-else-if="viewMode === 'list'">
      <!-- List View -->
      <div
        class="flex flex-col gap-[1.5vw] max-h-full overflow-y-auto scrollbar-none"
      >
        {{ data?.details }}
        <template v-for="(news, index) in newsList" :key="index">
          <div
            class="bg-neutral-100 h-[25vw] relative"
            @click="openDetail(news)"
          >
            <img
              :src="
                news?.image
                  ? news.image[0]?.url
                  : 'https://picsum.photos/200/300'
              "
              class="w-full h-full object-cover"
            />
            <div
              class="absolute w-full h-full p-[4.5vw] text-white top-0 right-0 flex gap-5 items-center bg-neutral-800/35"
            >
              <div class="flex-1 leading-tight">
                <h3 class="text-[2.2vh] font-bold">{{ news.title }}</h3>
                <p class="text-[1.2vh] font-semibold mt-[1vw]">
                  {{ news.abstract }}
                </p>
              </div>
              <div
                class="border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center mb-auto ms-auto"
              >
                <template v-if="news?.date">
              <p class="text-[1.8vh] font-bold leading-tight mb-0">
                {{ getDay(news?.date) }}
              </p>
              <p class="text-[1vh] font-semibold">
                {{ getMonth(news?.date) }}
              </p>
            </template>
            <template v-else>
              <p class="text-[1.8vh] font-bold leading-tight mb-0">
                <!-- {{ news?.start_date ? getDay(news?.start_date) : "" }}-{{ news?.end_date ? getDay(news?.end_date) : "" }} -->
                {{ getDateEvent(news?.start_date,news?.end_date) }}
              </p>
              <p class="text-[1vh] font-semibold">
                {{ getMonthEvent(news?.start_date ,news.end_date) }}
              </p>
            </template>

              </div>
            </div>
          </div>
        </template>
      </div>
    </template>

    <!-- Scroll View (Full-Screen and Reduced) -->
    <template v-else-if="viewMode === 'scroll'">
      <!-- Scroll View (Full-Screen and Reduced) -->
      <swiper
        :spaceBetween="1"
        :loop="true"
        :autoplay="{ delay: duration, disableOnInteraction: false }"
        :effect="'none'"
        :modules="[Autoplay, EffectFade]"
        class="mySwiper h-full"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="(news, index) in newsList"
          :key="index"
          class="h-full"
        >
          <div
            class="w-full h-full min-w-full bg-neutral-100 relative"
            @click="openDetail(news)"
          >
            <img
              :src="
                news?.image
                  ? news.image[0]?.url
                  : 'https://picsum.photos/200/300'
              "
              class="w-full h-full object-cover"
            />
            <div v-if="isSlim"
              class="absolute w-full h-full px-[4.5vw] pt-[3.8vw] pb-[2.2vw] text-white top-0 right-0 flex items-center bg-neutral-800/35"
            >
              <div class="w-full leading-tight">
                <h3 class="text-[2.2vh] font-bold">{{ news.title }}</h3>
                <p class="text-[1.2vh] font-semibold mt-[1vw]">
                  {{ news.abstract }}
                </p>
              </div>
              <div class="h-full flex flex-col justify-between">
                <div
                  class="border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center mb-auto ms-auto"
                >
           
                  <template v-if="news?.date">
              <p class="text-[1.8vh] font-bold leading-tight mb-0">
                {{ getDay(news?.date) }}
              </p>
              <p class="text-[1vh] font-semibold">
                {{ getMonth(news?.date) }}
              </p>
            </template>
            <template v-else-if="news?.start_date || news?.end_date">
              <p class="text-[1.8vh] font-bold leading-tight mb-0">
                <!-- {{ news?.start_date ? : "" }}-{{ news?.end_date ? getDay(news?.end_date) : "" }} -->
                {{ getDateEvent(news?.start_date,news?.end_date) }}
              </p>
              <p class="text-[1vh] font-semibold">
                {{ getMonthEvent(news?.start_date ,news.end_date) }}
              </p>
            </template>
                </div>
                <a class="flex items-center whitespace-nowrap gap-1"
                  ><span class="text-[1.6vh] font-semibold">Leggi tutto</span
                  ><Icon icon="si:arrow-right-duotone" class="w-[4vw] h-[4vw]"
                /></a>
              </div>
            </div>
            <div v-else
              class="absolute w-full h-full p-[8vw] text-white top-0 right-0 flex flex-col bg-neutral-800/35"
            >
              <div
                class="absolute top-[8vw] right-[8vw] border border-white rounded w-[14vw] h-[14vw] flex flex-col justify-center items-center ms-auto"
              >
             

                <template v-if="news?.date">
              <p class="text-[1.8vh] font-bold leading-tight mb-0">
                {{ getDay(news?.date) }}
              </p>
              <p class="text-[1vh] font-semibold">
                {{ getMonth(news?.date) }}
              </p>
            </template>
            <template v-else-if="news?.start_date || news?.end_date">
              <p class="text-[1.8vh] font-bold leading-tight mb-0" >
                <!-- {{ news?.start_date ? getDay(news?.start_date) : "" }}-{{ news?.end_date ? getDay(news?.end_date) : "" }} -->
                {{ getDateEvent(news?.start_date,news?.end_date) }}
              </p>
              <p class="text-[1vh] font-semibold">
                {{ getMonthEvent(news?.start_date ,news.end_date) }}
              </p>
            </template>
              </div>
              <div class="leading-tight pt-[17vw]">
                <h3 class="text-[3.8vh] font-bold">{{ news.title }}</h3>
                <p class="text-[1.8vh] font-semibold mt-[2vw]">
                  {{ news.abstract }}
                </p>
                <a class="flex items-center gap-1 mt-[5vw]"
                  ><span class="text-[1.6vh] font-semibold">Leggi tutto</span
                  ><Icon icon="si:arrow-right-duotone" class="w-[4vw] h-[4vw]"
                /></a>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </template>

    <template v-if="isDetailViewVisible">
      <!-- Detail View -->
      <div class="detail-view">
        <NewsEventDetail :data="selectedNews" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, watch, onMounted } from "vue";
import NewsEventScroll from "@/components/NewsEventScroll.vue";
import NewsEventDetail from "@/components/NewsEventDetail.vue";
import { useLayoutStore } from "@/stores/layoutStore";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperType } from "swiper/types";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";
import { Icon } from "@iconify/vue";
import { getDay, getMonth, getThinMonth } from "@/helpers/helpers";

const layoutStore = useLayoutStore();
const data: any = ref([]);
const swiperRef = ref<SwiperType | null>(null);

interface ComponentInfo {
  view_mode: string;
  items: any[];
  duration: number;
  // add other properties here
}
const props = defineProps<{
  layoutPosition: "top" | "center" | "bottom";
  componentInfo: ComponentInfo | null;
}>();

// State management
const viewMode = ref(
  props.layoutPosition == "center" ? props.componentInfo?.view_mode : "scroll"
);
const isSlim = ref(props.layoutPosition != "center");
const newsList = ref(props.componentInfo?.items || []); // Possible values: 'grid', 'list', 'scroll'
const isDetailViewVisible = ref(false);
const selectedNews = ref(null);
const duration = ref(
  props.componentInfo?.duration ? props.componentInfo?.duration * 1000 : 5000
);

function getDateEvent(start: string, end: string): string {
    if (start && end) {
        return `${getDay(start)}-${getDay(end)}`;
    }
    
    return start ? getDay(start) : end ? getDay(end) : '';
}

function getMonthEvent(start: string, end: string): string {
    if (start || end) {
        const initMonth = start ? getMonth(start) : '';
        const endMonth = end ? getMonth(end) : '';

        if (initMonth && endMonth) {
            return initMonth === endMonth ? initMonth : `${getThinMonth(start)}-${getThinMonth(end)}`;
        }

        return initMonth || endMonth;
    }
    return '';
}

// Methods for handling user actions
function openDetail(news: any) {
  selectedNews.value = news;
  isDetailViewVisible.value = true;
  layoutStore.requestFullScreen(props.layoutPosition);
}

watch(isDetailViewVisible, (newVal) => {
  console.log("isDetailViewVisible changed", isDetailViewVisible.value  )
  if (swiperRef.value) {
    if (newVal) {
      swiperRef.value.autoplay.stop(); // Pause Swiper when detail view is visible
    } else {
      swiperRef.value.autoplay.start(); // Resume Swiper when detail view is hidden
    }
  }
});

watch(
  () => layoutStore.isSectionExpanded(props.layoutPosition),
  (sectionExpanded: boolean) => {
    if (!sectionExpanded && isDetailViewVisible.value) {
      isDetailViewVisible.value = false;
    }
  }
);

function onSwiper(swiper: SwiperType) {
  swiperRef.value = swiper; // Set the Swiper instance
}
onMounted(() => {
  console.log("NewsEventsPlayable mounted", props.componentInfo);
});
</script>

<style scoped>
.detail-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  transform: translateX(0%);
  z-index: 3;
}
</style>
