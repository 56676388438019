<template>
  <div class="h-[6%] bg-neutral-600 px-5 grid grid-cols-3">
    <button @click="handleGoBack">
      <Icon icon="mdi:arrow-back" class="w-[3vh] h-[3vh] text-white" />
    </button>
    <button @click="handleHome" class="place-self-center">
      <Icon icon="mdi:home" class="w-[3vh] h-[3vh] text-white" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '@/stores/mainStore';
import { Icon } from '@iconify/vue';

const mainStore = useMainStore();

function handleGoBack() {
  mainStore.emitEvent('navigation:goBack');
}

function handleHome() {
  mainStore.emitEvent('navigation:home');
}
</script>
