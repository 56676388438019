import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/logo-prociv.png'


const _hoisted_1 = {
  key: 0,
  class: "w-full h-full flex gap-[3.5vw] items-center"
}
const _hoisted_2 = { class: "w-full text-[1.8vh] font-semibold" }
const _hoisted_3 = { class: "w-full flex justify-between" }
const _hoisted_4 = { class: "text-[1.6vh] font-regular" }
const _hoisted_5 = { class: "flex items-center gap-1" }
const _hoisted_6 = {
  key: 1,
  class: "min-h-full flex flex-col justify-around"
}
const _hoisted_7 = { class: "mt-[1.8vh]" }
const _hoisted_8 = { class: "gradient-gray flex gap-3 items-center px-[2vw] py-[1vw] text-gray-theme text-[1.8vh] font-bold leading-tight" }
const _hoisted_9 = { class: "w-[20%]" }
const _hoisted_10 = { class: "text-[1.4vh] font-medium leading-none" }
const _hoisted_11 = { class: "w-[20%]" }
const _hoisted_12 = { class: "text-[1.4vh] font-medium leading-none" }
const _hoisted_13 = { class: "w-[60%] flex items-center gap-[1.5vw]" }
const _hoisted_14 = { class: "text-[1.8vh] font-semibold" }
const _hoisted_15 = { class: "w-[20%] text-center" }
const _hoisted_16 = { class: "w-[20%] text-center" }

import { computed, ref, onMounted } from "vue";
import { useLayoutStore } from "@/stores/layoutStore";
import { Icon } from "@iconify/vue";
import ProcivIdrogeologico from "@/assets/icons/ProcivIdrogeologico.vue";
import ProcivVenti from "@/assets/icons/ProcivVenti.vue";
import ProcivIdraulico from "@/assets/icons/ProcivIdraulico.vue";
import ProcivValanghe from "@/assets/icons/ProcivValanghe.vue";

import ProcivNeve from "@/assets/icons/ProcivNeve.vue";

import ProcivTemporali from "@/assets/icons/ProcivTemporali.vue";
import { useProtezioneCivileStore } from "@/stores/protezioneCivileStore";

interface ComponentInfo {
  region: {
    name: string;
  };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProtezioneCivilePlayable',
  props: {
    layoutPosition: {},
    componentInfo: {}
  },
  setup(__props: any) {

const props = __props;

const isExpanded = computed(() => {
  return props.layoutPosition == "center" || layoutStore.isSectionExpanded(props.layoutPosition);
});
const layoutStore = useLayoutStore();
const protezioneCivileStore = useProtezioneCivileStore();

const isLoading = ref(true); // Loading state

onMounted(async () => {
  const region = props.componentInfo?.region?.name.toLowerCase() || "piemonte";
  await protezioneCivileStore.fetchAlertData(region);
  isLoading.value = false; // Set loading to false once data is fetched
  console.log("protezione civile component info", props.componentInfo);
});

const generalState = computed(
  () => protezioneCivileStore.todayAlert?.generalState || "N/A"
);
const generalStateDescription = computed(() =>
  protezioneCivileStore.getColorDescription(generalState.value)
);
const todayDate = computed(() => protezioneCivileStore.todayAlert?.date || "");
const tomorrowDate = computed(
  () => protezioneCivileStore.tomorrowAlert?.date || ""
);

// Map alert details to display categories
const alertDetails = computed(() => [
  {
    type: "Idrogeologico",
    today: protezioneCivileStore.todayAlert?.risks.idrogeologica || "none",
    tomorrow:
      protezioneCivileStore.tomorrowAlert?.risks.idrogeologica || "none",
    iconComponent: ProcivIdrogeologico,
  },
  {
    type: "Idraulico",
    today: protezioneCivileStore.todayAlert?.risks.idraulica || "none",
    tomorrow: protezioneCivileStore.tomorrowAlert?.risks.idraulica || "none",
    iconComponent: ProcivIdraulico,
  },
  {
    type: "Temporali forti",
    today: protezioneCivileStore.todayAlert?.risks.temporali || "none",
    tomorrow: protezioneCivileStore.tomorrowAlert?.risks.temporali || "none",
    iconComponent: ProcivTemporali,
  },
  {
    type: "Neve",
    today: protezioneCivileStore.todayAlert?.risks.neve || "none",
    tomorrow: protezioneCivileStore.tomorrowAlert?.risks.neve || "none",
    iconComponent: ProcivNeve,
  },
  {
    type: "Valanghe",
    today: protezioneCivileStore.todayAlert?.risks.valanghe || "none",
    tomorrow: protezioneCivileStore.tomorrowAlert?.risks.valanghe || "none",
    iconComponent: ProcivValanghe,
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ expanded: isExpanded.value }, 'h-full']),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(layoutStore).requestFullScreen(props.layoutPosition)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
        'h-full relative cursor-pointer transition-all duration-500 ease-in-out': true,
        'bg-white p-[6vw] h-full w-full absolute top-0 left-0 overflow-y-auto scrollbar-none':
          isExpanded.value,
        [generalState.value + ' h-full w-full px-[6vw] py-[2vw] text-white']:
          !isExpanded.value,
      })
    }, [
      (!isExpanded.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[4] || (_cache[4] = _createElementVNode("img", {
              src: _imports_0,
              class: "w-[18vw]"
            }, null, -1)),
            _createElementVNode("div", _hoisted_2, [
              _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-[2.8vh] font-bold leading-tight mb-[1.2vw]" }, " Allerte di Protezione Civile ", -1)),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex mb-[1.2vw]" }, [
                _createElementVNode("div", { class: "flex items-center gap-1" }),
                _createElementVNode("div", { class: "flex items-center gap-1 ms-[3vh]" })
              ], -1)),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(generalStateDescription.value), 1),
                _createElementVNode("a", _hoisted_5, [
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-[1.6vh] font-semibold" }, "Leggi tutto", -1)),
                  _createVNode(_unref(Icon), {
                    icon: "si:arrow-right-duotone",
                    class: "w-[4vw] h-[4vw]"
                  })
                ])
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _cache[8] || (_cache[8] = _createElementVNode("div", null, [
              _createElementVNode("div", { class: "flex gap-[1.5vw] mb-[.1vh]" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "w-[20vw]"
                }),
                _createElementVNode("h2", { class: "text-[3.8vh] font-bold leading-tight" }, " Allerte di Protezione Civile ")
              ]),
              _createElementVNode("p", { class: "text-[1.8vh] font-semibold text-gray-theme leading-tight" }, " Il servizio espone i dati emessi dalla Protezione Civile di Regione Lombardia per i livelli di allerta dei rischi naturali. ")
            ], -1)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "w-[60%]" }, [
                  _createElementVNode("h4", { class: "uppercase" }, "Allerta")
                ], -1)),
                _createElementVNode("div", _hoisted_9, [
                  _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "uppercase leading-none" }, "Oggi", -1)),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(todayDate.value), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "uppercase leading-none" }, "Domani", -1)),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(tomorrowDate.value), 1)
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(alertDetails.value, (alert, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "flex gap-3 items-center px-[2vw] py-[1vw] border-b last:border-none border-neutral-400",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_13, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(alert.iconComponent), { class: "w-[5vw] h-[5vw] text-gray-theme-200" })),
                    _createElementVNode("span", _hoisted_14, _toDisplayString(alert.type), 1)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("span", {
                      class: _normalizeClass(['indicator', alert.today])
                    }, null, 2)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("span", {
                      class: _normalizeClass(['indicator', alert.tomorrow])
                    }, null, 2)
                  ])
                ]))
              }), 128))
            ]),
            _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"mt-[2.2vh]\" data-v-75e12d4c><p class=\"text-gray-theme text-[1.8vh] font-bold leading-tight uppercase ms-8 mb-3\" data-v-75e12d4c> Legenda: </p><div class=\"gradient-gray flex flex-col gap-1 px-[3vw] py-[2vw] text-[1.8vh]\" data-v-75e12d4c><div class=\"flex items-center gap-5\" data-v-75e12d4c><span class=\"indicator none\" data-v-75e12d4c></span> Nessuna previsione </div><div class=\"flex items-center gap-5\" data-v-75e12d4c><span class=\"indicator green\" data-v-75e12d4c></span><p data-v-75e12d4c><span class=\"font-bold\" data-v-75e12d4c>Codice Verde</span> - Criticità assente </p></div><div class=\"flex items-center gap-5\" data-v-75e12d4c><span class=\"indicator yellow\" data-v-75e12d4c></span><p data-v-75e12d4c><span class=\"font-bold\" data-v-75e12d4c>Codice Giallo</span> - Criticità ordinaria </p></div><div class=\"flex items-center gap-5\" data-v-75e12d4c><span class=\"indicator orange\" data-v-75e12d4c></span><p data-v-75e12d4c><span class=\"font-bold\" data-v-75e12d4c>Codice Arancione</span> - Criticità moderata </p></div><div class=\"flex items-center gap-5\" data-v-75e12d4c><span class=\"indicator red\" data-v-75e12d4c></span><p data-v-75e12d4c><span class=\"font-bold\" data-v-75e12d4c>Codice Rosso</span> - Criticità elevata </p></div></div></div>", 1))
          ]))
    ], 2)
  ], 2))
}
}

})