import { defineStore } from "pinia";
import { onMounted, onBeforeUnmount, markRaw } from "vue";
import { LayoutType } from "@/stores/settingsStore";
import NewsEventsPlayable from "@/components/NewsEventsPlayable.vue";
import ProtezioneCivilePlayable from "@/components/ProtezioneCivilePlayable.vue";
import ImagePlayable from "@/components/ImagePlayable.vue";
import AxiosClient from "@/Axios/AxiosClient";

export interface LayoutState {
  layoutData: LayoutData;
  layoutType: LayoutType;
  isTopExpanded: boolean;
  isCenterExpanded: boolean;
  isBottomExpanded: boolean;
  loading: boolean;
  isLayoutBusy: boolean;
}

enum PlayableType {
  NewsPlay = "NewsPlay",
  CivilProtectionPlay = "CivilProtectionPlay",
  ImagePlay = "ImagePlay",
  EventPlay = "EventPlay",
  // Add more types as needed
}

interface LayoutSection {
  id: number;
  type: PlayableType;
  play_id: number;
  totem_id: number;
  scenario_id: number;
  position: "HEADER" | "BODY" | "FOOTER";
  created_at: string;
  updated_at: string;
  details: Object;
  component: Object | null;
}

interface LayoutData {
  HEADER?: LayoutSection | null;
  BODY?: LayoutSection | null;
  FOOTER?: LayoutSection | null;
}

const componentMapping: Record<PlayableType, any> = {
  [PlayableType.NewsPlay]: markRaw(NewsEventsPlayable),
  [PlayableType.CivilProtectionPlay]: markRaw(ProtezioneCivilePlayable),
  [PlayableType.ImagePlay]: markRaw(ImagePlayable),
  [PlayableType.EventPlay]: markRaw(NewsEventsPlayable),
  // Add more mappings as needed
};

export function getComponentByType(type: PlayableType) {
  return componentMapping[type] || null; // Return the component or null if not found
}

export const useLayoutStore = defineStore("layoutStore", {
  state: (): LayoutState => ({
    layoutType: LayoutType.FULL_SCREEN,
    layoutData: {
      HEADER: null,
      BODY: null,
      FOOTER: null,
    } as LayoutData,
    isTopExpanded: false,
    isCenterExpanded: false,
    isBottomExpanded: false,
    loading: false,
    isLayoutBusy: false,
  }),

  getters: {
    // Getter that returns the expansion state based on layout position
    isSectionExpanded: (state) => (position: "top" | "center" | "bottom") => {
      if (position === "top") return state.isTopExpanded;
      if (position === "center") return state.isCenterExpanded;
      if (position === "bottom") return state.isBottomExpanded;
      return false;
    },
  },

  actions: {
    async fetchPlayables() {
      this.loading = true;
      try {
        const response = await AxiosClient.get("/totem/layout/playabes");

        // console.log(response.data);  // Logga l'intera risposta per capire la struttura dei dati
        const data = response.data; // Supponiamo che i dati siano nella proprietà 'data'
        this.loading = false;
        console.log("retrieved data by layoutstore", data);
        console.log("retrieved data by layoutstore", data.BODY);
        this.layoutData = {
          HEADER:
            data.HEADER && data.HEADER.length > 0
              ? this.mapSectionData(data.HEADER[0])
              : null,
          BODY: this.getBodySection(data),
          FOOTER:
            data.FOOTER && data.FOOTER.length > 0
              ? this.mapSectionData(data.FOOTER[0])
              : null,
        };
        console.log("layoutData", this.layoutData);
      } catch (error: any) {
        console.error(error);
        this.loading = false;
        return;
      }
    },

    getBodySection(data: any): LayoutSection | null {
      let sectionData = null;
      if (data.BODY && data.BODY.length > 0) {
        sectionData = data.BODY[0];
      } else if (data.FULL_SCREEN && data.FULL_SCREEN.length > 0) {
        sectionData = data.FULL_SCREEN[0];
      } else {
        return null;
      }
      return this.mapSectionData(sectionData);
    },

    mapSectionData(section: any): LayoutSection {
      console.log("mappping section", section);
      return {
        id: section.id,
        type: section.type as PlayableType,
        play_id: section.play_id,
        totem_id: section.totem_id,
        scenario_id: section.scenario_id,
        position: section.position,
        created_at: section.created_at,
        updated_at: section.updated_at,
        details: section.details,
        component: getComponentByType(section.type),
      };
    },
    expandTop() {
      this.isTopExpanded = true;
      console.log("Top section expanded");
    },

    expandCenter() {
      this.isCenterExpanded = true;
      console.log("Center section expanded");
    },

    expandBottom() {
      this.isBottomExpanded = true;
      console.log("Bottom section expanded");
    },

    shrinkAll() {
      console.log("Resetting all sections to default");
      this.isTopExpanded = false;
      this.isCenterExpanded = false;
      this.isBottomExpanded = false;
    },
    requestFullScreen(origin: "top" | "center" | "bottom") {
      this.shrinkAll();
      console.log(`Requesting full screen from ${origin}`);
      if (origin === "top") this.expandTop();
      else if (origin === "center") this.expandCenter();
      else if (origin === "bottom") this.expandBottom();
    },

    // Event handlers for navigation events
    goBackEventReceived() {
      console.log("Navigation: Go Back event received");
      if (!!this.isLayoutBusy) return;
      this.shrinkAll();
    },

    homeEventReceived() {
      console.log("Navigation: Home event received");
      this.shrinkAll();
      this.isLayoutBusy = false;
    },

    setupEventListeners() {
      window.addEventListener("navigation:goBack", this.goBackEventReceived);
      window.addEventListener("navigation:home", this.homeEventReceived);
    },

    removeEventListeners() {
      window.removeEventListener("navigation:goBack", this.goBackEventReceived);
      window.removeEventListener("navigation:home", this.homeEventReceived);
    },
  },
});

// Integrating the setup and cleanup of event listeners in a Vue component
export function useLayoutEventListeners() {
  const layoutStore = useLayoutStore();

  onMounted(() => {
    layoutStore.setupEventListeners();
  });

  onBeforeUnmount(() => {
    layoutStore.removeEventListeners();
  });
}
