import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-full bg-white" }
const _hoisted_2 = {
  key: 0,
  class: "grid grid-cols-2 gap-[1.5vw] max-h-full overflow-y-auto scrollbar-none"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "bg-neutral-100 h-[49vw] relative" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "absolute w-full h-full p-[4.5vw] text-white top-0 right-0 flex flex-col bg-neutral-800/35" }
const _hoisted_7 = { class: "border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center ms-auto" }
const _hoisted_8 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_9 = { class: "text-[1vh] font-semibold" }
const _hoisted_10 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_11 = { class: "text-[1vh] font-semibold" }
const _hoisted_12 = { class: "leading-tight mt-auto" }
const _hoisted_13 = { class: "text-[2.2vh] font-bold" }
const _hoisted_14 = { class: "text-[1.2vh] font-semibold mt-[1vw]" }
const _hoisted_15 = {
  key: 1,
  class: "flex flex-col gap-[1.5vw] max-h-full overflow-y-auto scrollbar-none"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "absolute w-full h-full p-[4.5vw] text-white top-0 right-0 flex gap-5 items-center bg-neutral-800/35" }
const _hoisted_19 = { class: "flex-1 leading-tight" }
const _hoisted_20 = { class: "text-[2.2vh] font-bold" }
const _hoisted_21 = { class: "text-[1.2vh] font-semibold mt-[1vw]" }
const _hoisted_22 = { class: "border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center mb-auto ms-auto" }
const _hoisted_23 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_24 = { class: "text-[1vh] font-semibold" }
const _hoisted_25 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_26 = { class: "text-[1vh] font-semibold" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = ["src"]
const _hoisted_29 = {
  key: 0,
  class: "absolute w-full h-full px-[4.5vw] pt-[3.8vw] pb-[2.2vw] text-white top-0 right-0 flex items-center bg-neutral-800/35"
}
const _hoisted_30 = { class: "w-full leading-tight" }
const _hoisted_31 = { class: "text-[2.2vh] font-bold" }
const _hoisted_32 = { class: "text-[1.2vh] font-semibold mt-[1vw]" }
const _hoisted_33 = { class: "h-full flex flex-col justify-between" }
const _hoisted_34 = { class: "border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center mb-auto ms-auto" }
const _hoisted_35 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_36 = { class: "text-[1vh] font-semibold" }
const _hoisted_37 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_38 = { class: "text-[1vh] font-semibold" }
const _hoisted_39 = { class: "flex items-center whitespace-nowrap gap-1" }
const _hoisted_40 = {
  key: 1,
  class: "absolute w-full h-full p-[8vw] text-white top-0 right-0 flex flex-col bg-neutral-800/35"
}
const _hoisted_41 = { class: "absolute top-[8vw] right-[8vw] border border-white rounded w-[14vw] h-[14vw] flex flex-col justify-center items-center ms-auto" }
const _hoisted_42 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_43 = { class: "text-[1vh] font-semibold" }
const _hoisted_44 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_45 = { class: "text-[1vh] font-semibold" }
const _hoisted_46 = { class: "leading-tight pt-[17vw]" }
const _hoisted_47 = { class: "text-[3.8vh] font-bold" }
const _hoisted_48 = { class: "text-[1.8vh] font-semibold mt-[2vw]" }
const _hoisted_49 = { class: "flex items-center gap-1 mt-[5vw]" }
const _hoisted_50 = {
  key: 3,
  class: "detail-view"
}

import { ref, watch, onMounted } from "vue";
import NewsEventScroll from "@/components/NewsEventScroll.vue";
import NewsEventDetail from "@/components/NewsEventDetail.vue";
import { useLayoutStore } from "@/stores/layoutStore";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperType } from "swiper/types";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";
import { Icon } from "@iconify/vue";
import { getDay, getMonth, getThinMonth } from "@/helpers/helpers";

interface ComponentInfo {
  view_mode: string;
  items: any[];
  duration: number;
  // add other properties here
}

export default /*@__PURE__*/_defineComponent({
  __name: 'NewsEventsPlayable',
  props: {
    layoutPosition: {},
    componentInfo: {}
  },
  setup(__props: any) {

const layoutStore = useLayoutStore();
const data: any = ref([]);
const swiperRef = ref<SwiperType | null>(null);

const props = __props;

// State management
const viewMode = ref(
  props.layoutPosition == "center" ? props.componentInfo?.view_mode : "scroll"
);
const isSlim = ref(props.layoutPosition != "center");
const newsList = ref(props.componentInfo?.items || []); // Possible values: 'grid', 'list', 'scroll'
const isDetailViewVisible = ref(false);
const selectedNews = ref(null);
const duration = ref(
  props.componentInfo?.duration ? props.componentInfo?.duration * 1000 : 5000
);

function getDateEvent(start: string, end: string): string {
    if (start && end) {
        return `${getDay(start)}-${getDay(end)}`;
    }
    
    return start ? getDay(start) : end ? getDay(end) : '';
}

function getMonthEvent(start: string, end: string): string {
    if (start || end) {
        const initMonth = start ? getMonth(start) : '';
        const endMonth = end ? getMonth(end) : '';

        if (initMonth && endMonth) {
            return initMonth === endMonth ? initMonth : `${getThinMonth(start)}-${getThinMonth(end)}`;
        }

        return initMonth || endMonth;
    }
    return '';
}

// Methods for handling user actions
function openDetail(news: any) {
  selectedNews.value = news;
  isDetailViewVisible.value = true;
  layoutStore.requestFullScreen(props.layoutPosition);
}

watch(isDetailViewVisible, (newVal) => {
  console.log("isDetailViewVisible changed", isDetailViewVisible.value  )
  if (swiperRef.value) {
    if (newVal) {
      swiperRef.value.autoplay.stop(); // Pause Swiper when detail view is visible
    } else {
      swiperRef.value.autoplay.start(); // Resume Swiper when detail view is hidden
    }
  }
});

watch(
  () => layoutStore.isSectionExpanded(props.layoutPosition),
  (sectionExpanded: boolean) => {
    if (!sectionExpanded && isDetailViewVisible.value) {
      isDetailViewVisible.value = false;
    }
  }
);

function onSwiper(swiper: SwiperType) {
  swiperRef.value = swiper; // Set the Swiper instance
}
onMounted(() => {
  console.log("NewsEventsPlayable mounted", props.componentInfo);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (viewMode.value === 'grid')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newsList.value, (news, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "bg-neutral-100 aspect-square relative",
              onClick: ($event: any) => (openDetail(news))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("img", {
                  src: 
                  news?.image
                    ? news.image[0]?.url
                    : 'https://picsum.photos/200/300'
                ,
                  class: "w-full h-full object-cover"
                }, null, 8, _hoisted_5),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (news?.date)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(getDay)(news?.date)), 1),
                          _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(getMonth)(news?.date)), 1)
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("p", _hoisted_10, _toDisplayString(getDateEvent(news?.start_date,news?.end_date)), 1),
                          _createElementVNode("p", _hoisted_11, _toDisplayString(getMonthEvent(news?.start_date ,news.end_date)), 1)
                        ], 64))
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("h3", _hoisted_13, _toDisplayString(news?.title), 1),
                    _createElementVNode("p", _hoisted_14, _toDisplayString(news?.abstract), 1)
                  ])
                ])
              ])
            ], 8, _hoisted_3))
          }), 128))
        ]))
      : (viewMode.value === 'list')
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createTextVNode(_toDisplayString(data.value?.details) + " ", 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newsList.value, (news, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "bg-neutral-100 h-[25vw] relative",
                onClick: ($event: any) => (openDetail(news))
              }, [
                _createElementVNode("img", {
                  src: 
                news?.image
                  ? news.image[0]?.url
                  : 'https://picsum.photos/200/300'
              ,
                  class: "w-full h-full object-cover"
                }, null, 8, _hoisted_17),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("h3", _hoisted_20, _toDisplayString(news.title), 1),
                    _createElementVNode("p", _hoisted_21, _toDisplayString(news.abstract), 1)
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    (news?.date)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("p", _hoisted_23, _toDisplayString(_unref(getDay)(news?.date)), 1),
                          _createElementVNode("p", _hoisted_24, _toDisplayString(_unref(getMonth)(news?.date)), 1)
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("p", _hoisted_25, _toDisplayString(getDateEvent(news?.start_date,news?.end_date)), 1),
                          _createElementVNode("p", _hoisted_26, _toDisplayString(getMonthEvent(news?.start_date ,news.end_date)), 1)
                        ], 64))
                  ])
                ])
              ], 8, _hoisted_16))
            }), 128))
          ]))
        : (viewMode.value === 'scroll')
          ? (_openBlock(), _createBlock(_unref(Swiper), {
              key: 2,
              spaceBetween: 1,
              loop: true,
              autoplay: { delay: duration.value, disableOnInteraction: false },
              effect: 'none',
              modules: [_unref(Autoplay), _unref(EffectFade)],
              class: "mySwiper h-full",
              onSwiper: onSwiper
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newsList.value, (news, index) => {
                  return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                    key: index,
                    class: "h-full"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "w-full h-full min-w-full bg-neutral-100 relative",
                        onClick: ($event: any) => (openDetail(news))
                      }, [
                        _createElementVNode("img", {
                          src: 
                news?.image
                  ? news.image[0]?.url
                  : 'https://picsum.photos/200/300'
              ,
                          class: "w-full h-full object-cover"
                        }, null, 8, _hoisted_28),
                        (isSlim.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                              _createElementVNode("div", _hoisted_30, [
                                _createElementVNode("h3", _hoisted_31, _toDisplayString(news.title), 1),
                                _createElementVNode("p", _hoisted_32, _toDisplayString(news.abstract), 1)
                              ]),
                              _createElementVNode("div", _hoisted_33, [
                                _createElementVNode("div", _hoisted_34, [
                                  (news?.date)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createElementVNode("p", _hoisted_35, _toDisplayString(_unref(getDay)(news?.date)), 1),
                                        _createElementVNode("p", _hoisted_36, _toDisplayString(_unref(getMonth)(news?.date)), 1)
                                      ], 64))
                                    : (news?.start_date || news?.end_date)
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                          _createElementVNode("p", _hoisted_37, _toDisplayString(getDateEvent(news?.start_date,news?.end_date)), 1),
                                          _createElementVNode("p", _hoisted_38, _toDisplayString(getMonthEvent(news?.start_date ,news.end_date)), 1)
                                        ], 64))
                                      : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("a", _hoisted_39, [
                                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-[1.6vh] font-semibold" }, "Leggi tutto", -1)),
                                  _createVNode(_unref(Icon), {
                                    icon: "si:arrow-right-duotone",
                                    class: "w-[4vw] h-[4vw]"
                                  })
                                ])
                              ])
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_40, [
                              _createElementVNode("div", _hoisted_41, [
                                (news?.date)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createElementVNode("p", _hoisted_42, _toDisplayString(_unref(getDay)(news?.date)), 1),
                                      _createElementVNode("p", _hoisted_43, _toDisplayString(_unref(getMonth)(news?.date)), 1)
                                    ], 64))
                                  : (news?.start_date || news?.end_date)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _createElementVNode("p", _hoisted_44, _toDisplayString(getDateEvent(news?.start_date,news?.end_date)), 1),
                                        _createElementVNode("p", _hoisted_45, _toDisplayString(getMonthEvent(news?.start_date ,news.end_date)), 1)
                                      ], 64))
                                    : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("div", _hoisted_46, [
                                _createElementVNode("h3", _hoisted_47, _toDisplayString(news.title), 1),
                                _createElementVNode("p", _hoisted_48, _toDisplayString(news.abstract), 1),
                                _createElementVNode("a", _hoisted_49, [
                                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-[1.6vh] font-semibold" }, "Leggi tutto", -1)),
                                  _createVNode(_unref(Icon), {
                                    icon: "si:arrow-right-duotone",
                                    class: "w-[4vw] h-[4vw]"
                                  })
                                ])
                              ])
                            ]))
                      ], 8, _hoisted_27)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["autoplay", "modules"]))
          : _createCommentVNode("", true),
    (isDetailViewVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
          _createVNode(NewsEventDetail, { data: selectedNews.value }, null, 8, ["data"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})