<template>
  <!-- Thumbnail grid view (initial view) -->
  <div v-if="!isFullScreen" class="grid grid-cols-4 gap-[1.5vw] pb-[5vh]">
    <template v-for="(img, i) in images" :key="i">
      <img
        :src="img"
        class="w-full aspect-square cursor-pointer object-cover"
        @click="openGallery(i)"
      />
    </template>
  </div>

  <!-- Full-screen Swiper gallery view -->
  <div v-if="isFullScreen" class="full-screen">
    <swiper
      :style="{
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
      }"
      :spaceBetween="10"
      :navigation="true"
      :initial-slide="currentIndex"
      :thumbs="{ swiper: thumbsSwiper }"
      :modules="modules"
      class="mySwiper2"
    >
      <swiper-slide v-for="(img, i) in images" :key="i">
        <img :src="img" class="object-contain w-full h-full"/>
      </swiper-slide>
    </swiper>
    <swiper
      @swiper="setThumbsSwiper"
      :spaceBetween="10"
      :slidesPerView="4"
      :freeMode="true"
      :watchSlidesProgress="true"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide v-for="(img, i) in images" :key="'thumb' + i">
        <img :src="img" class="object-cover w-full aspect-square" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onUnmounted } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

// Props
const props = withDefaults(
  defineProps<{
    images: string[]; // Array of image URLs
    fullScreen: boolean;
  }>(),
  {
    fullScreen: false,
  }
);
const emit = defineEmits(["galleryEnterFullScreen", "galleryExitFullScreen"]);

// State and methods
const isFullScreen = computed(() => props.fullScreen);
const currentIndex = ref(0);
const thumbsSwiper:any = ref(null);

const openGallery = (index: number) => {
  currentIndex.value = index;
  emit("galleryEnterFullScreen");
};

const closeGallery = () => {
  emit("galleryExitFullScreen");
};

// Handle reinitialization of Swiper on entering full-screen
watch(isFullScreen, (newValue) => {
  if (!newValue) {
    destroySwiper();
  } else {
    reinitializeSwiper();
  }
});

// Function to reinitialize Swiper
const reinitializeSwiper = () => {
  thumbsSwiper.value = null;
  // Delay to allow for DOM to be reset
  setTimeout(() => {
    thumbsSwiper.value = thumbsSwiper.value;
  }, 0);
};

// Function to destroy Swiper instance
const destroySwiper = () => {
  if (thumbsSwiper.value) {
    thumbsSwiper.value.destroy();
    thumbsSwiper.value = null;
  }
};

onUnmounted(() => {
  destroySwiper();
});

// Helper to set the thumbs swiper reference
const setThumbsSwiper = (swiper: any) => {
  thumbsSwiper.value = swiper;
};

const modules = [FreeMode, Navigation, Thumbs];
</script>

<style scoped>
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.mySwiper2 {
  height: 82%;
  width: 100%;
}

.mySwiper {
  box-sizing: border-box;
  padding: 15px 0;
  width: 100%;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
</style>
