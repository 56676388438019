import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-[6%] bg-neutral-600 px-5 grid grid-cols-3" }

import { useMainStore } from '@/stores/mainStore';
import { Icon } from '@iconify/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationBar',
  setup(__props) {

const mainStore = useMainStore();

function handleGoBack() {
  mainStore.emitEvent('navigation:goBack');
}

function handleHome() {
  mainStore.emitEvent('navigation:home');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", { onClick: handleGoBack }, [
      _createVNode(_unref(Icon), {
        icon: "mdi:arrow-back",
        class: "w-[3vh] h-[3vh] text-white"
      })
    ]),
    _createElementVNode("button", {
      onClick: handleHome,
      class: "place-self-center"
    }, [
      _createVNode(_unref(Icon), {
        icon: "mdi:home",
        class: "w-[3vh] h-[3vh] text-white"
      })
    ])
  ]))
}
}

})