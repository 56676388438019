import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-[6%] bg-white px-5 flex iteems-center justify-between border-b border-neutral-300" }
const _hoisted_2 = { class: "flex items-center gap-5" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex flex-col justify-items-start" }
const _hoisted_5 = { class: "text-[2.2vh] text-left font-semibold leading-[1]" }
const _hoisted_6 = { class: "flex items-center gap-5" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "text-[1.6vh] text-right leading-[1]" }
const _hoisted_9 = { class: "text-[2.2vh] text-right font-semibold leading-[1]" }

import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useSettingsStore } from '@/stores/settingsStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderBar',
  setup(__props) {

const settingsStore = useSettingsStore();
const logo = computed(() => settingsStore.logo);
const cityName = computed(() => settingsStore.scenarioName);

const currentTime = ref('');
const formattedDate = ref('');

// Function to update date and time
const updateDateTime = () => {
  const now = new Date();
  formattedDate.value = now.toLocaleDateString('it-IT', {
    weekday: 'long',
    day: 'numeric',
    month: 'long'
  });
  currentTime.value = now.toLocaleTimeString('it-IT', {
    hour: '2-digit',
    minute: '2-digit'
  });
};

onMounted(() => {
  updateDateTime();
  const interval = setInterval(updateDateTime, 1000); // Update every minute

  // Clear interval when component is unmounted
  onUnmounted(() => {
    clearInterval(interval);
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: logo.value ?? '',
        alt: "City Logo",
        class: "w-[3.2vh]"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-[1.6vh] text-left leading-[1]" }, "Comune di", -1)),
        _createElementVNode("span", _hoisted_5, _toDisplayString(cityName.value), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, _toDisplayString(formattedDate.value), 1),
        _createElementVNode("span", _hoisted_9, _toDisplayString(currentTime.value), 1)
      ])
    ])
  ]))
}
}

})