<template>
  <div id="layout" class="relative h-[88%] w-full overflow-hidden">
    <!-- Top Section -->
    <div
      v-if="
        layoutType === LayoutType.HEADER_BODY_FOOTER ||
        layoutType === LayoutType.HEADER_BODY
      "
      :class="[
        'section',
        'top-section',
        { 'expanded-top': layoutStore.isTopExpanded },
      ]"
    >
      <slot name="top" />
      <template v-if="layoutComponents.HEADER">
        <component
          :is="layoutComponents.HEADER.component"
          layout-position="top"
          :component-info="layoutComponents.HEADER.details"
        />
      </template>
    </div>

    <!-- Main Section (Center) -->
    <div
      :class="[
        'section',
        mainSectionClass,
        { 'expanded-center': layoutStore.isCenterExpanded },
      ]"
    >
      <!-- <slot name="center" /> -->
      <template v-if="layoutComponents.BODY">
        <component
          :is="layoutComponents.BODY.component"
          layout-position="center"
          :component-info="layoutComponents.BODY.details"
        />
      </template>
    </div>

    <!-- Bottom Section -->
    <div
      v-if="
        layoutType === LayoutType.HEADER_BODY_FOOTER ||
        layoutType === LayoutType.BODY_FOOTER
      "
      :class="[
        'section',
        'bottom-section',
        { 'expanded-bottom': layoutStore.isBottomExpanded },
      ]"
    >
      <!-- <slot name="bottom" /> -->
      <template v-if="layoutComponents.FOOTER">
        <component
          :is="layoutComponents.FOOTER.component"
          layout-position="bottom"
          :component-info="layoutComponents.FOOTER.details"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, computed, onMounted, onBeforeUnmount } from "vue";
import { LayoutType } from "@/stores/settingsStore";
import { useLayoutStore } from "@/stores/layoutStore";

const layoutStore = useLayoutStore();

const layoutType = computed(() => layoutStore.layoutType);
const layoutComponents = computed(() => layoutStore.layoutData);

const mainSectionClass = computed(() => {
  if (layoutType.value === LayoutType.HEADER_BODY_FOOTER) {
    return "main-section-center";
  } else if (layoutType.value === LayoutType.HEADER_BODY) {
    return "main-section-bottom";
  } else if (layoutType.value === LayoutType.BODY_FOOTER) {
    return "main-section-top";
  } else {
    return "main-section-full";
  }
});

onMounted(() => {
  layoutStore.setupEventListeners();
});

onBeforeUnmount(() => {
  layoutStore.removeEventListeners();
});
</script>

<style scoped>
#layout {
  position: relative;
}

/* General Section Styles */
.section {
  transition: all 0.5s ease;
  position: absolute;
  width: 100%;
  overflow: hidden;
}

/* Default Top Section */
.top-section {
  height: 15%;
  top: 0;
  z-index: 1;
  background-color: black;
}
.expanded-top {
  height: 100%;
  top: 0;
  z-index: 100;
}

/* Default Main Section (Center) */
.main-section-center {
  height: 70%;
  top: 15%;
  z-index: 2;
  background-color: black;
}
.main-section-bottom {
  height: 85%;
  top: 15%;
  z-index: 2;
  background-color: black;
}

.main-section-top {
  height: 85%;
  top: 0;
  z-index: 2;
  background-color: black;
}

.main-section-full {
  bottom: 0;
  top: 0;
  z-index: 2;
  background-color: black;
}
.expanded-center {
  height: 100%;
  top: 0;
  z-index: 100;
}

/* Default Bottom Section */
.bottom-section {
  height: 15%;
  bottom: 0;
  z-index: 1;
  background-color: black;
}
.expanded-bottom {
  height: 100%;
  bottom: 0;
  z-index: 100;
}
</style>
