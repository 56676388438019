import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full" }

import { computed, onMounted } from 'vue';
import Carousel from './Carousel.vue';
import ImageContent from '@/components/ImageContent.vue';

interface ComponentInfo {
  transition: 'slide' | 'fade' | 'cube' | 'coverflow';  // Swiper supported effects
  duration: number;
  images: { url: string }[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ImagePlayable',
  props: {
    layoutPosition: {},
    componentInfo: {}
  },
  setup(__props: any) {

const props = __props;

const duration = computed(() => (props.componentInfo?.duration || 5) * 1000);
const imageComponents = computed(() => {
  return (
    props.componentInfo?.images.map((image) => ({
      component: ImageContent,
      props: { src: image.url },
    })) || []
  );
});

onMounted(() => {
  console.log('ImagePlayable mounted', props.componentInfo);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Carousel, {
      tipoScroll: _ctx.componentInfo?.transition,
      items: imageComponents.value,
      duration: duration.value,
      class: "h-full"
    }, null, 8, ["tipoScroll", "items", "duration"])
  ]))
}
}

})