<template>
  <CarouselComponent
   v-bind="$attrs"
    id="standby-carousel"
    v-if="settingsStandByMedia"
    :media="settingsStandByMedia"
    :transitionTime="scrollDuration"
    :transitionType="
      settingsStandByTransition ? settingsStandByTransition : 'scroll'
    "
  ></CarouselComponent>
  <div
    class="w-full h-full absolute top-0 left-0 bg-slate-600 opacity-35"
  ></div>
  <div class="absolute top-[7vh] left-0 w-full flex flex-col text-white">
    <div class="flex flex-col items-center">
      <img :src="logo ?? ''" alt="City Logo" class="w-[10vh] mb-3" />
      <span class="text-[2.5vh] font-semibold">Comune di</span>
      <span class="text-[6vh] font-semibold leading-[1]">{{ cityName }}</span>
    </div>
  </div>

  <div class="circle-ripple">
    <div class="rim1 absolute left-[50%]"></div>
    <div class="rim2 absolute left-[50%]"></div>
    <div class="rim3 absolute left-[50%]"></div>
  </div>
  <Icon
    icon="majesticons:hand-pointer-2"
    class="absolute top-[50vh] w-[6vh] h-[6vh] text-white animate-bounce-vertical"
    style="left: calc(50% - 2.2vh)"
  />
  <div
    class="w-full absolute top-[58vh] text-white text-center text-[2.5vh] font-semibold"
  >
    Tocca per iniziare ad esplorare
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, computed } from 'vue';
import { useSettingsStore } from '@/stores/settingsStore';
import CarouselComponent from '@/components/CarouselComponent.vue';
import { Icon } from '@iconify/vue';

const logo = computed(() => settingsStore.logo);
const cityName = computed(() => settingsStore.scenarioName);
const settingsStore = useSettingsStore();
const settingsStandByMedia = computed(() => settingsStore.standbyMedia);
const settingsStandByTime = computed(() => settingsStore.standbyTime ?? 5000);
const scrollDuration = computed(() => settingsStore.scrollDuration ?? 3000);
const settingsStandByTransition = computed(
  () => settingsStore.standByTransition
);
</script>
<style scoped>
#standby-carousel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
</style>
