import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute top-[7vh] left-0 w-full flex flex-col text-white" }
const _hoisted_2 = { class: "flex flex-col items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text-[6vh] font-semibold leading-[1]" }

import { onMounted, onUnmounted, ref, computed } from 'vue';
import { useSettingsStore } from '@/stores/settingsStore';
import CarouselComponent from '@/components/CarouselComponent.vue';
import { Icon } from '@iconify/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'StandBy',
  setup(__props) {

const logo = computed(() => settingsStore.logo);
const cityName = computed(() => settingsStore.scenarioName);
const settingsStore = useSettingsStore();
const settingsStandByMedia = computed(() => settingsStore.standbyMedia);
const settingsStandByTime = computed(() => settingsStore.standbyTime ?? 5000);
const scrollDuration = computed(() => settingsStore.scrollDuration ?? 3000);
const settingsStandByTransition = computed(
  () => settingsStore.standByTransition
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (settingsStandByMedia.value)
      ? (_openBlock(), _createBlock(CarouselComponent, _mergeProps({ key: 0 }, _ctx.$attrs, {
          id: "standby-carousel",
          media: settingsStandByMedia.value,
          transitionTime: scrollDuration.value,
          transitionType: 
      settingsStandByTransition.value ? settingsStandByTransition.value : 'scroll'
    
        }), null, 16, ["media", "transitionTime", "transitionType"]))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-full h-full absolute top-0 left-0 bg-slate-600 opacity-35" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: logo.value ?? '',
          alt: "City Logo",
          class: "w-[10vh] mb-3"
        }, null, 8, _hoisted_3),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-[2.5vh] font-semibold" }, "Comune di", -1)),
        _createElementVNode("span", _hoisted_4, _toDisplayString(cityName.value), 1)
      ])
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "circle-ripple" }, [
      _createElementVNode("div", { class: "rim1 absolute left-[50%]" }),
      _createElementVNode("div", { class: "rim2 absolute left-[50%]" }),
      _createElementVNode("div", { class: "rim3 absolute left-[50%]" })
    ], -1)),
    _createVNode(_unref(Icon), {
      icon: "majesticons:hand-pointer-2",
      class: "absolute top-[50vh] w-[6vh] h-[6vh] text-white animate-bounce-vertical",
      style: {"left":"calc(50% - 2.2vh)"}
    }),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "w-full absolute top-[58vh] text-white text-center text-[2.5vh] font-semibold" }, " Tocca per iniziare ad esplorare ", -1))
  ], 64))
}
}

})