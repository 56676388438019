import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "gradient-gray rounded-3xl p-[4vw] text-gray-theme flex flex-col gap-5"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex-1" }
const _hoisted_4 = {
  key: 1,
  class: "pdf-viewer full-screen"
}
const _hoisted_5 = ["src"]

import { ref, watch, computed } from "vue";
import { VuePDF, usePDF } from "@tato30/vue-pdf";
import { Icon } from "@iconify/vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PdfViewerComponent',
  props: {
    pdfList: {},
    fullScreen: { type: Boolean }
  },
  emits: ["pdfEnterFullScreen", "pdfExitFullScreen"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const isFullScreen = computed(() => props.fullScreen);
const loading = ref(true);
const debugText = ref("");
const currentIndex = ref(0);

let loadedPages = ref(0);

const onPageLoaded = () => {
  loadedPages.value += 1;

  if (loadedPages.value === pages.value) {
    loading.value = false;
  }
};

let { pdf, pages } = { pdf: null, pages: null };

const openPdf = (index: number) => {
  debugText.value = "Click received on index " + index;
  // ({ pdf, pages } = usePDF(props.pdfList[index] || ""));
  currentIndex.value = index;
  emit("pdfEnterFullScreen");
  debugText.value = "opening" + props.pdfList[index].file_name;

  watch(pages, (newPageCount) => {
    loadedPages.value = 0;
    loading.value = true;
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isFullScreen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.pdfList && _ctx.pdfList.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.pdfList, (element, i) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["flex gap-5 items-center text-[1.8vh] font-semibold leading-tight bg-red-200 cursor-pointer", isFullScreen.value ? 'bg-green-200' : 'bg-yellow-200']),
                    onClick: ($event: any) => (openPdf(i))
                  }, [
                    _createVNode(_unref(Icon), {
                      icon: "ci:link",
                      class: "w-[4vw] h-[4vw]"
                    }),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(element.file_name), 1)
                  ], 10, _hoisted_2),
                  _createElementVNode("div", null, _toDisplayString(debugText.value), 1)
                ], 64))
              }), 128))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (isFullScreen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("iframe", {
            src: props.pdfList[currentIndex.value].url,
            class: "pdf-iframe h-full w-full",
            frameborder: "0"
          }, null, 8, _hoisted_5)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})