import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid grid-cols-4 gap-[1.5vw] pb-[5vh]"
}
const _hoisted_2 = ["src", "onClick"]
const _hoisted_3 = {
  key: 1,
  class: "full-screen"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

import { ref, computed, watch, onUnmounted } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'GalleryComponent',
  props: {
    images: {},
    fullScreen: { type: Boolean, default: false }
  },
  emits: ["galleryEnterFullScreen", "galleryExitFullScreen"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

// State and methods
const isFullScreen = computed(() => props.fullScreen);
const currentIndex = ref(0);
const thumbsSwiper:any = ref(null);

const openGallery = (index: number) => {
  currentIndex.value = index;
  emit("galleryEnterFullScreen");
};

const closeGallery = () => {
  emit("galleryExitFullScreen");
};

// Handle reinitialization of Swiper on entering full-screen
watch(isFullScreen, (newValue) => {
  if (!newValue) {
    destroySwiper();
  } else {
    reinitializeSwiper();
  }
});

// Function to reinitialize Swiper
const reinitializeSwiper = () => {
  thumbsSwiper.value = null;
  // Delay to allow for DOM to be reset
  setTimeout(() => {
    thumbsSwiper.value = thumbsSwiper.value;
  }, 0);
};

// Function to destroy Swiper instance
const destroySwiper = () => {
  if (thumbsSwiper.value) {
    thumbsSwiper.value.destroy();
    thumbsSwiper.value = null;
  }
};

onUnmounted(() => {
  destroySwiper();
});

// Helper to set the thumbs swiper reference
const setThumbsSwiper = (swiper: any) => {
  thumbsSwiper.value = swiper;
};

const modules = [FreeMode, Navigation, Thumbs];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isFullScreen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (img, i) => {
            return (_openBlock(), _createElementBlock("img", {
              key: i,
              src: img,
              class: "w-full aspect-square cursor-pointer object-cover",
              onClick: ($event: any) => (openGallery(i))
            }, null, 8, _hoisted_2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (isFullScreen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_unref(Swiper), {
            style: {
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
      },
            spaceBetween: 10,
            navigation: true,
            "initial-slide": currentIndex.value,
            thumbs: { swiper: thumbsSwiper.value },
            modules: modules,
            class: "mySwiper2"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (img, i) => {
                return (_openBlock(), _createBlock(_unref(SwiperSlide), { key: i }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: img,
                      class: "object-contain w-full h-full"
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["initial-slide", "thumbs"]),
          _createVNode(_unref(Swiper), {
            onSwiper: setThumbsSwiper,
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
            modules: modules,
            class: "mySwiper"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (img, i) => {
                return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                  key: 'thumb' + i
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: img,
                      class: "object-cover w-full aspect-square"
                    }, null, 8, _hoisted_5)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})