// axiosConfig.ts
import axios from "axios";
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL ? `${process.env.VUE_APP_BASE_URL}/api/` : "http://api.totem.localhost/api/", // Imposta l'URL di base
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercetta ogni richiesta e aggiunge automaticamente il token
apiClient.interceptors.request.use(
  (config) => {
    const urlParams = new URLSearchParams(window.location.search);
    const token =
      process.env.VUE_APP_ENV == "local"
        ? process.env.VUE_APP_API_TOKEN
        :  (urlParams.get('token') || '');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiClient;
